import React, { useState } from 'react';
import "../GuidedExperience/GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import Input from '../common/Input/Input';
import RadioButton from '../common/RadioGroup/RadioButton';

interface ApartmentUnitsWizardProps {
    apartmentStyles: Record<string, unknown>[];
    multiresUnitSubtype: Record<string, unknown>[];
    apartmentNumber: Record<string, unknown>;
    updateApartmentNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
    questionType: "apt" | "bst" | "pen" | "lrg" | "sml";
    aptTypeMap: Record<string, string[]>;
}

export default function ApartmentUnitsWizard(props: ApartmentUnitsWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const checkNoInput = () : boolean => {
        let initialState = false;
        props.aptTypeMap[props.questionType].forEach((typeCode) => {
            if ((props.apartmentNumber[props.apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[typeCode] !== 0) {
                initialState = true;
            }
        });
        return initialState;
    }

    const [showTable, setShowTable] = useState(checkNoInput());

    return (<div>
        {props.questionType !== "apt" && <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t(`apartment-units-wizard.apartment-units-question-${props.questionType}`)}</legend>
                <RadioButton name="apartment-units-question" id="apartment-units-yes" text={t("yes")} textPosition='right' value="true" currentValue={showTable} onChange={() => setShowTable(true)} />
                <RadioButton name="apartment-units-question" id="apartment-units-no" text={t("no")} textPosition='right' value="false" currentValue={showTable} onChange={() => setShowTable(false)} />
            </fieldset>
        </div>}
        {(showTable || props.questionType === "apt") && <>
            <p>{t(`apartment-units-wizard.apartment-units-description-${props.questionType}`)}</p>
            <table className='wizard-table'>
                <thead>
                    <tr><th colSpan={props.aptTypeMap[props.questionType].length}>{t("apartment-headings.suite-type")}</th></tr>
                    <tr>
                        {props.multiresUnitSubtype.filter((subtype) => props.aptTypeMap[props.questionType].includes(subtype["unitSubtypeCode"] as string)).map((subtype, index) => {
                            return <th key={`apartment-units-header-${index}`}>
                                <label htmlFor={`apartment-units-${props.apartmentStyles[0]["unitStyleCode"]}-${subtype["unitSubtypeCode"]}`}>
                                    {props.questionType === "apt" ? (subtype.unitSubtypeDescription as string) : (subtype.unitSubtypeDescription as string).substring((subtype.unitSubtypeDescription as string).indexOf(" ") + 1)}
                                </label>
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {props.multiresUnitSubtype.filter((subtype) => props.aptTypeMap[props.questionType].includes(subtype["unitSubtypeCode"] as string)).map((subtype, index) => {
                            return <td key={`apartment-units-row-${index}`} className='wizard-grey-out-cell'>
                                {(props.questionType === "apt" 
                                || (subtype["unitSubtypeCode"] === "315" && (props.apartmentNumber[props.apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)["308"] !== 0) 
                                || (props.apartmentNumber[props.apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[props.aptTypeMap["apt"][(props.aptTypeMap[props.questionType].indexOf(subtype["unitSubtypeCode"] as string))]] !== 0) 
                                && <Input type='text' id={`apartment-units-${props.apartmentStyles[0]["unitStyleCode"]}-${subtype["unitSubtypeCode"]}`} 
                                    name={`apartment-units-${props.apartmentStyles[0]["unitStyleCode"]}-${subtype["unitSubtypeCode"]}`} onChange={props.updateApartmentNumber} 
                                    value={((props.apartmentNumber[props.apartmentStyles[0]["unitStyleCode"] as string] as Record<string, unknown>)[subtype["unitSubtypeCode"] as string] as number).toString()} />}
                            </td>
                        })}
                    </tr>
                </tbody>
            </table>
        </>}
    </div>)
}