import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IncomeAttributeState } from "../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer";
import { RootState } from "../../../redux/reducers/root";
import { LookupsState } from "../../../redux/reducers/getLookups/lookupsReducer";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { getLookups } from "../../../redux/actions/lookups/lookups";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from "../../../redux/actions/incomeAttributes/incomeAttributes";
import { resetSaving } from "../../../redux/actions/saveData/saveData";
import { toast } from "react-toastify";
import { SaveResultState } from "../../../redux/reducers/getSaveData/saveResultReducer";
import Total from "../../../components/common/Total/Total";
import MenuState from "../../../types/menu/MenuState";

export default function FixedCharges() {
  const { t } = useTranslation(['common', 'pie']);
  const dispatch = useDispatch();
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);

  const [totalFixedCharges, setTotalFixedCharges] = useState(0);

  // calculated variables for keeping code clean
  const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
  const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string);

  // error messages
  const [errors, setErrors] = useState({});

  const validateTable = useCallback((showError = true) => {
    if (localStorage.getItem("isInternalUser")) {
      return true;
    }

    const requiredCodes = ['49', '54'];
    let isValid = true;
    // Get all income attribute objects with data
    const filteredData = (incomeAttributesState.data as Record<string, unknown>[]).filter(row => {
      return requiredCodes.includes(row['code'] as string);
    });

    // if a required field is empty
    if (filteredData.length !== requiredCodes.length ||
      filteredData.some(r => r['value'] === null || r['value'] === undefined)) {
      lookupState.lookups[2]["fixedCharges"].forEach(e => {
        if (requiredCodes.includes(e.incomeAttributeCode) && filteredData) {
          filteredData.forEach(r => {
            // Map to the current code and verify it's empty
            if (r['code'] === e.incomeAttributeCode && (r['value'] === null || r['value'] === undefined)) {
              if (showError) {
                setErrors(prevErrors => ({ ...prevErrors, [e.incomeAttributeCode]: t('required') }));
              }
            }
          })
        }
        // If filtered data array is empty
        else if (requiredCodes.includes(e.incomeAttributeCode)) {
          if (showError) {
            setErrors(prevErrors => ({ ...prevErrors, [e.incomeAttributeCode]: t('required') }));
          }
        }
      })
      isValid = false;
    }
    return isValid;
  }, [incomeAttributesState.data, lookupState.lookups, t]);

  // This hook sends a request to fetch income attribute data if the following conditions are met:
  // 1. Return type is import
  // 2. A request to fetch wasn't alreadt sent
  // 3. If system has a failed response and/or result of a different snapshot stored
  useEffect(() => {
    if ((!incomeAttributesState.loading && !incomeAttributesState.success)
      || incomeAttributesState.snapshotId !== currentSnapshotId) {
      dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
    }
    // eslint-disable-next-line
  }, []);

  // This hook fetches income attribute lookups if they are not already loaded in system
  useEffect(() => {
    if (!lookupState.success) {
      dispatch(getLookups(lookupType));
    }
  }, [dispatch, lookupState.success, lookupType]);

  // If table is valid, this hook sends a save request and handles response
  useEffect(() => {
    if (saveData.saving) {
      if (validateTable()) {
        const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
        dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes, menuState.activeItem.name));
      }
      else {
        toast.error(t("toastMessage.validationFailed") as string);
        dispatch(resetSaving());
      }
    }
  }, [dispatch, t, validateTable, incomeAttributesState.data, saveData.saving, menuState.activeItem.name])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
    }
  }, [dispatch, currentSnapshotId, saveResultState]);

  const calculateTotal = useCallback(() => {
    let total = 0;
    let filteredAttributes: Record<string, unknown>[] = [];

    if (incomeAttributesState.data) {
      filteredAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter(row => {
        return lookupState.lookups[2]["fixedCharges"].some(f => {
          return f.incomeAttributeCode === row['code']
        })
      });

      filteredAttributes.forEach(record => {
        if (record['value']) {
          total += record['value'] as number
        }
      });
    }
    return total;
  }, [incomeAttributesState.data, lookupState.lookups]);

  // This hook calculates Total Motel Revenue by tracking changes to income attribute data
  useEffect(() => {
    if (!incomeAttributesState.loading && incomeAttributesState.data && lookupState.success) {
      const total = calculateTotal();
      setTotalFixedCharges(total);
    }
    // eslint-disable-next-line
  }, [incomeAttributesState, calculateTotal, lookupState.success, dispatch, validateTable]);

  // This functions handles changes made to income attribute data columns
  const handleIncomeAttrChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      setErrors(prevErrors => ({ ...prevErrors, [code]: '' }));
      const row = (incomeAttributesState.data as Record<string, unknown>[]).find(row => row.code === code);
      const na = newAmt ? newAmt : null;
      if (row) {
        row['value'] = newAmt;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: currentSnapshotId,
          code: code,
          description: null,
          value: na,
          type: "2",
          percent: null,
          year: null,
        });
      }

      // Update totals
      const newTotal = calculateTotal();
      setTotalFixedCharges(newTotal);
      validateTable();
    }
  }
  const handleCommentChange = (value: string, name: string) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(row => row.code === code);
      const v = value.trim() === "" ? null : value.trim();
      if (incomeAttributesData) {
        incomeAttributesData["description"] = v;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: currentSnapshotId,
          code: code,
          description: v,
          value: null,
          type: "2",
          percent: null,
          year: null,
        });
      }
    }
  }

  return (<>
    <div className="partial-width">
      <table className="table-with-border pie-table">
        <thead></thead>
        <tbody>
          {
            lookupState.lookups[2]["fixedCharges"] && lookupState.lookups[2]["fixedCharges"].map((lookup, key) => {
              if (lookup.incomeAttributeCode === "54" || lookup.incomeAttributeCode === "49") {
                return <IncomeAttributeTableFragmentGenerator key={key} attribute={lookup}
                  data={(incomeAttributesState.data as Record<string, unknown>[]).find(row => lookup.incomeAttributeCode === String(row.code))}
                  valueChangeHandler={handleIncomeAttrChange}
                  handleCommentChange={handleCommentChange}
                  showSubheading={false}
                  hasInputLabel={false}
                  isOtherLabel={false}
                  isRequiredField={true}
                  isReplacementReserve={false}
                  errors={errors}
                />
              } else if (lookup.incomeAttributeCode === "77") {
                return <IncomeAttributeTableFragmentGenerator key={key} attribute={lookup}
                  data={(incomeAttributesState.data as Record<string, unknown>[]).find(row => lookup.incomeAttributeCode === String(row.code))}
                  valueChangeHandler={handleIncomeAttrChange}
                  handleCommentChange={handleCommentChange}
                  showSubheading={false}
                  hasInputLabel={true}
                  isPlaceHolderWithAttrDesc={true}
                  isOtherLabel={false}
                  isRequiredField={false}
                  isReplacementReserve={false}
                />
              }
              else {
                return <IncomeAttributeTableFragmentGenerator key={key} attribute={lookup}
                  data={(incomeAttributesState.data as Record<string, unknown>[]).find(row => lookup.incomeAttributeCode === String(row.code))}
                  valueChangeHandler={handleIncomeAttrChange}
                  handleCommentChange={handleCommentChange}
                  showSubheading={false}
                  hasInputLabel={false}
                  isOtherLabel={false}
                  isRequiredField={false}
                  isReplacementReserve={false}
                />
              }
            })
          }
        </tbody>
      </table>
      <div className='total-container'>
        <Total text={t('expenses.totalFixedCharges', { ns: 'pie' })} value={totalFixedCharges} />
      </div>
      <p>{t('alteast-one-mandatory-field')}</p>
    </div>
  </>);
}