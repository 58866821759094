import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useReadOnly } from '../../utils/ReadOnlyContext';
import "./GEV.css";
import { OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';
import { FarmingOperationCommercialGrainClass, PerCrop } from '../../types/gev/BusinessOperationsData';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { useFormErrorContext } from '../../utils/context/FormErrorContext';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function isFarmingOperation(dataset: any, field: string): dataset is FarmingOperationCommercialGrainClass {
    return dataset && 
    typeof dataset.selected === 'boolean' &&
    field in dataset
}
export default function GEVPerCrop(props: OperationFieldProps): JSX.Element {
    const { operation, name } = props;
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly();
    const { formData, setFormData } = useBusinessOperationsState();
    const { errors } = useFormErrorContext();
    const [errorFields, setErrorFields] = useState({});
    const [localCrops, setLocalCrops] = useState<PerCrop | undefined>(isFarmingOperation(formData.farmingOperationCommercialGrain, props.name) ? 
    formData.farmingOperationCommercialGrain?.[props.name as 'acresPerCrop' | 'yieldPerCrop'] : {
        corn: null,
        soybeans: null,
        wheat: null,
        other: null
    })

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey && error.primaryKey.split('.')[2] === props.name) {
                    const localKey = error.primaryKey.split('.').pop() as string;
                    setErrorFields(prevData => ({ ...prevData, [localKey]: error.message }));
                }
            })
        }
    }, [errors, props.name]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, key: keyof PerCrop) => {
        const { value } = e.target;
        setLocalCrops(prevData => {
            let dataset = prevData as PerCrop;
            dataset = { ...dataset, [key]: value };
            return { ...dataset }
         })
    };
    
    useEffect(() => {
        if (props.name as keyof FarmingOperationCommercialGrainClass === "acresPerCrop") {
            setFormData((prevData) => {
                const dataset = prevData.farmingOperationCommercialGrain;
                dataset.acresPerCrop = localCrops;
                return {
                    ...prevData,
                    [props.operation]: { ...dataset }
                }
            })
        }
        else if (props.name as keyof FarmingOperationCommercialGrainClass === "yieldPerCrop") {
            setFormData((prevData) => {
                const dataset = prevData.farmingOperationCommercialGrain;
                dataset.yieldPerCrop = localCrops;
                return {
                    ...prevData,
                    [props.operation]: { ...dataset }
                }
            })
        }

    }, [localCrops, props.name, props.operation, setFormData]);
    
    return (
        <tr className='gev-input-tr'>
            <td className='index'>
                {props.index && props.index + "."}
            </td>
            <td>
                <div className='gev-input-label'>{t(operation + "." + name)}</div>
                <div className='gev-space-between padding-bottom-percrop'>
                    {
                        localCrops && Object.entries(localCrops).map(([key, cropData], index) => {
                            return (
                                <div key={key} className='flex flex-column align-item-start'>
                                    <p className='gev-crop-text'>{t(operation + "." + name + "Options." + key)}</p>
                                    <input
                                        id={`${operation}.${name}.${key}`}
                                        name={`${operation}.${name}.${key}`}
                                        value={cropData ?? ""}
                                        title={t(operation + "." + name + "Options." + key)}
                                        disabled={isReadOnly}
                                        type={(index === 3) ? "text" : "number"}
                                        autoComplete="off"
                                        aria-label={t(operation + "." + name + "Options." + key)}
                                        onChange={(e) => handleChange(e, key as keyof PerCrop)}
                                        className={(key in errorFields) ? 'form-error': ''}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                {/* <div className='flex flex-column align-item-start'>
                    <div>{t(operation + "." + name + "Options.other")}</div>
                    <input type="text"
                        id={operation + "." + name + ".other"}
                        name={operation + "." + name + ".other"}
                        value={(data["other"] === null) ? "" : data["other"]}
                        title={t(operation + "." + name + "Options.other")}
                        aria-label={t(operation + "." + name + "Options.other")}
                        autoComplete="off"
                        onChange={() => {
                            //
                        }}
                    />
                    <div className="input-error-message">
                        {errors["other"]}
                    </div>
                </div>
                <div className="input-error-message">
                    {errors["options"]}
                </div> */}
            </td>
        </tr >
    )
}
