/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 * Update: 18/04/2024 - Mariam Bawa - Cast ELS Income attribute response to work with updated LookupsState
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from '../../../redux/actions/incomeAttributes/incomeAttributes';
import { getLookups } from '../../../redux/actions/lookups/lookups';
import { RootState } from '../../../redux/reducers/root';
import { IncomeAttributeState } from '../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer';
import { LookupsState } from '../../../redux/reducers/getLookups/lookupsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import CapitalCostTab from './CapitalCostTab';
import MenuState from '../../../types/menu/MenuState';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { ControlledAccordion, useAccordionProvider } from '@szhsin/react-accordion';
import AccordionItem, { DefaultAccordionProviderOptions } from '../../../components/common/AccordionItem/AccordionItem';
import Total from '../../../components/common/Total/Total';
import calculateIncomeAttributesTotal from '../../../utils/calculateIncomeAttributesTotal';
import IncomeAttributeLookupObject from '../../../types/lookups/IncomeAttributeLookupObject';
import "../PIE.css";

export default function RevenueContainer(): JSX.Element {
  const { t } = useTranslation(['navigation', 'pie']);
  const dispatch = useDispatch();
  const providerValue = useAccordionProvider(DefaultAccordionProviderOptions)
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const tabList = menuState.activeItem.subItems;

  const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
  const [total, setTotal] = useState<number | undefined>(undefined)

  const calcTotal = () => {
    const lookups = [...lookupState.lookups[3]["GLF.course"], ...lookupState.lookups[3]["GLF.building"]]
    const total = calculateIncomeAttributesTotal(lookups as IncomeAttributeLookupObject[],
      incomeAttributesState.data as Record<string, unknown>[])
    setTotal(total)
  }

  useEffect(() => {
    if ((!incomeAttributesState.loading && !incomeAttributesState.success)
      || incomeAttributesState.snapshotId !== Number(localStorage.getItem('currentSnapshotId') as string)
    ) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!incomeAttributesState.loading && incomeAttributesState.success
      && !lookupState.loading && lookupState.success
      && lookupState.lookups[3]) {
      calcTotal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeAttributesState, lookupState])

  useEffect(() => {
    if (saveData.saving) {
      const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
      dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes, menuState.activeItem.name));
    }
  }, [dispatch, incomeAttributesState.data, menuState.activeItem.name, saveData.saving])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
  }, [dispatch, saveResultState]);

  useEffect(() => {
    if (!lookupState.success) {
      dispatch(getLookups(lookupType));
    }
  }, [dispatch, lookupState.success, lookupType]);


  const findAttributeData = (attributeCode: string) => {
    let incomeAttributesData: Record<string, unknown> | undefined = undefined;
    if (incomeAttributesState.data) {
      incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
    }
    return incomeAttributesData;
  }

  const handleCurrencyChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const na = newAmt ? newAmt : null;
      if (incomeAttributesData) {
        incomeAttributesData["value"] = na;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: null,
          value: na,
          type: "3",
          percent: null,
          year: null,
        });
      }
      calcTotal()
    }
  }

  return (
    <div className='partial-width'>
      <div className='content-container'>
        {
          (menuState.activeItem.hasTabs && tabList) && (
            <>
              <div className="accordion">
                <ControlledAccordion providerValue={providerValue}>
                  {tabList.map(tab => {
                    return <AccordionItem header={t(tab.name, { ns: 'navigation' })}
                      key={tab.name}
                      itemKey={tab.name}
                    >
                      <CapitalCostTab
                        name={tab.name}
                        valueChangeHandler={handleCurrencyChange}
                        lookups={lookupState.lookups[3] ? lookupState.lookups[3][tab.name] : []}
                        incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined}
                      />
                    </AccordionItem>
                  })}
                </ControlledAccordion>
              </div>
            </>



            // <>
            //   <Tabs selectedIndex={menuState.activeItem.selectedTabIndex}
            //     onSelect={handleTabSelect}>
            //     <TabList className={'tab-container'}>
            //       {tabList?.map(tab => {
            //         return <Tab key={tab.id} selectedClassName='active-tab' className="tab" title={t(tab.name + ".tooltip", { ns: 'pie' })} >{t(tab.name)}</Tab>
            //       })}
            //     </TabList>
            //     {
            //       tabList?.map(tab => {
            //         return <TabPanel key={tab.id}>
            //           <CapitalCostTab
            //             name={tab.name}
            //             lookups={lookupState.lookups[3] ? lookupState.lookups[3][tab.name] : []}
            //             incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined} />
            //         </TabPanel>
            //       })
            //     }
            //   </Tabs>
            // </>
          )
        }
      </div>

      <div className='total-container'>
        <Total text={t('costs.total', { ns: 'pie' })} value={total} />
      </div>

    </div>);
}