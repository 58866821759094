import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/root'
import RoomStatisticsData from '../../../types/roomStatistics/RoomStatisticsData'
import { useTranslation } from 'react-i18next'
import DataTable from '../../../components/common/DataTable/DataTable'
import TableRow, { TableCellData } from '../../../components/common/TableRow/TableRow'
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer'
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile'
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer'
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer'
import updateProperties from '../../../utils/DataModifier/DataModifier'

function RoomStatistics() {
    const dispatch = useDispatch();
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)

    const initialRoomStatisticsData: RoomStatisticsData = {
        "numberOfRoom": null,
        "roomAvailable": null,
        "occupancyPercent": null,
        "averageDailyRate": null,
    }

    const [roomData, setRoomData] = useState<RoomStatisticsData>(initialRoomStatisticsData as RoomStatisticsData)
    const { t } = useTranslation('room_statistics');

    const roomStatisticsHeadings = [
        <th title={t("roomTitleNumOfRoom")} id='num-of-rooms' className='pier-datatable-clear-th' key="room-column-1-header">{t("roomNumOfRoomHeader")}</th>,
        <th title={t("roomTitleRoomsAvailable")} id='rooms-available' className='pier-datatable-clear-th' key="room-column-2-header">{t("roomRoomsAvailableHeader")}</th>,
        <th title={t("roomTitleOccupancy")} id='occupancy-percent' className='pier-datatable-clear-th' key="room-column-3-header">{t("roomOccupancyHeader")}</th>,
        <th title={t("roomTitleAvgDaily")} id='avg-daily-rate' className='pier-datatable-clear-th' key="room-column-4-header">{t("roomAvgDailyHeader")}</th>,
    ];

    const roomStatisticsRow: TableCellData[] = [
        { name: "numberOfRoom", type: "integer", header: "num-of-rooms", forced: true, positiveOnly: true },
        { name: "roomAvailable", type: "integer", header: "rooms-available", forced: true, positiveOnly: true },
        { name: "occupancyPercent", type: "float", symbol: '%', header: "occupancy-percent", forced: true, positiveOnly: true },
        { name: "averageDailyRate", type: "float", symbol: '$', header: "avg-daily-rate", forced: true, positiveOnly: true },
    ]

    const handleRoomStatisticsChange = (name: keyof RoomStatisticsData, value: string | boolean | number | null) => {
        setRoomData(oldRoomData => ({
            ...oldRoomData,
            [name]: value
        }))
    }

    useEffect(() => {
        dispatch(resetGetProfileAttributes())
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!profileAttributes.loading && profileAttributes.data) {
            const initData = { ...initialRoomStatisticsData }
            updateProperties(initData, profileAttributes.data)
            setRoomData(initData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAttributes.data, profileAttributes.loading])

    useEffect(() => {
        if (saveData.saving) {
            const data = { ...profileAttributes.data, ...roomData } as Record<string, unknown>
            dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, data))
        }
    }, [currentSnapshotId, dispatch, profileAttributes.data, profileOverview.profile.profile_type_code, roomData, saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId));
        }
    }, [currentSnapshotId, dispatch, profileOverview.profile.profile_type_code, saveResultState.status]);

    return (
        <div className='partial-width'>
            <h3>{t("roomTableHeader")}</h3>
            <DataTable
                title={t("roomTableHeader")}
                headings={roomStatisticsHeadings}>
                <>
                    <TableRow cellData={roomStatisticsRow} dataType={roomData} onChange={handleRoomStatisticsChange} />
                </>
            </DataTable>
        </div>
    )
}

export default RoomStatistics