import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { toast } from 'react-toastify';
import { getHelpFile } from '../../redux/actions/file/file';
import { RootState } from '../../redux/reducers/root';
import { FileState } from '../../redux/reducers/getFile/getFileReducer';
import { RESET_HELP_FILE } from '../../redux/actionTypes/actionsTypes';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import Footer from '../../layouts/Footer/Footer';
import ReactGA from 'react-ga4';
import "./HelpCentre.css";

export default function HelpCentre(): JSX.Element {
    const { i18n } = useTranslation();
    const { t } = useTranslation('help');
    const dispatch = useDispatch();
    const [requesting, setRequesting] = useState<boolean>(false);
    const [fileKey, setFileKey] = useState("");
    const [pdf, setPdf] = useState<string>("");
    const fileState = useSelector((state: RootState) => state.fileReducer.file as FileState);

    useEffect(() => {
        if (fileKey) {
            dispatch({ type: RESET_HELP_FILE });
            dispatch(getHelpFile(i18n.language, fileKey));
            setRequesting(true);
        }
    }, [dispatch, fileKey, i18n.language]);

    useEffect(() => {
        if (requesting && fileState.loading === false) {
            setRequesting(false);
            if (fileState.status === 200) {
                if (fileState.s3url) {
                    setPdf(fileState.s3url);
                }
                else if (fileState.link) {
                    window.open(fileState.link, "_blank", "noreferrer");
                }
            } else {
                dispatch({ type: RESET_HELP_FILE });
                setFileKey("");
                setPdf("");
                toast.error("Could not load the file");
            }
        }
    }, [dispatch, fileState.link, fileState.loading, fileState.s3url, fileState.status, requesting]);

    const handleLinkSelection = (key: string) => {
        ReactGA.event({
            category: 'User interaction with External/Resources Links',
            action: 'External Link click',
            label: `${key} Page`,
        });
        console.log("Triggered resources link click event")
        setFileKey(key)
    }
    return (
        <>
            <div className='helpcentre-container'>
                <div className='helpcentre-definition'>
                    <Sidebar className='definition' aria-label='sidebar'>
                        <div className='definition-title'>{t('definitions')}</div>
                        <Menu aria-label={t('definitions')}>
                            <MenuItem onClick={() => setFileKey("multires")}>
                                <span className='wrap-text'>{t('multires')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("comind")} aria-label={t('comind')}>
                                <span className='wrap-text'>{t('comind')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("golf")} aria-label={t('golf')}>
                                <span className='wrap-text'>{t('golf')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("hotel")} aria-label={t('hotel')}>
                                <span className='wrap-text'>{t('hotel')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("land-lease")} aria-label={t('land-lease')}>
                                <span className='wrap-text'>{t('land-lease')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("motel")} aria-label={t('motel')}>
                                <span className='wrap-text'>{t('motel')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("rhome")} aria-label={t('rhome')}>
                                <span className='wrap-text'>{t('rhome')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("pie")} aria-label={t('pie')}>
                                <span className='wrap-text'>{t('pie')}</span>
                            </MenuItem>
                        </Menu>
                        <div className='definition-title'>{t('samples')}</div>
                        <Menu aria-label={t('samples')}>
                            <MenuItem onClick={() => setFileKey("sample-multires")} aria-label={t('sample-multires')}>
                                <span className='wrap-text'>{t('sample-multires')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("sample-comind")} aria-label={t('sample-comind')}>
                                <span className='wrap-text'>{t('sample-comind')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("sample-rhome")} aria-label={t('sample-rhome')}>
                                <span className='wrap-text'>{t('sample-rhome')}</span>
                            </MenuItem>
                        </Menu>
                        <div className='definition-title'>&nbsp;</div>
                        <Menu aria-label='extra'>
                            <MenuItem onClick={() => setFileKey("assessment-requests")} aria-label={t('assessment-requests')}>
                                <span className='wrap-text'>{t('assessment-requests')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => handleLinkSelection("user-guide")} aria-label={t('user-guide')}>
                                <span className='wrap-text'>{t('user-guide')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => handleLinkSelection("faq")} aria-label={t('faq')}>
                                <span className='wrap-text'>{t('faq')}</span>
                            </MenuItem>
                            <MenuItem onClick={() => setFileKey("policy")} aria-label={t('policy')}>
                                <span className='wrap-text'>{t('policy')}</span>
                            </MenuItem>
                        </Menu>
                    </Sidebar>
                </div>
                <div className='helpcentre-iframe'>
                    {
                        requesting ?
                            <LoadingSpinner loading={requesting}></LoadingSpinner>
                            :
                            <>
                                {pdf &&
                                    <div className='iframe'>
                                        <h1>
                                            {fileKey && t("fileHeader." + fileKey)}
                                        </h1>
                                        <iframe src={pdf}
                                            title={t("fileHeader." + fileKey)} width="100%" height="99%">
                                        </iframe>
                                    </div>
                                }
                            </>
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}
