import React, { useEffect, useState } from 'react'
import './ParkingPropertySummary.css'
import { useTranslation } from 'react-i18next';
import DataTable from '../../../components/common/DataTable/DataTable';
import TableRow, { TableCellData } from '../../../components/common/TableRow/TableRow';
import RowKey from '../../../components/common/RowKey/RowKey';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { resetSaving } from '../../../redux/actions/saveData/saveData';
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { toast } from 'react-toastify';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { setNextActiveMenuItem } from '../../../redux/actions/menu/menu';
import { initialMenuItem } from '../../../redux/reducers/getMenu/getMenuReducer';
import ParkingData from '../../../types/parkingProperty/ParkingData';
import PropertyData from '../../../types/parkingProperty/PropertyData';
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer';

function ParkingPropertySummary() {
  const { t } = useTranslation('parking_property');
  const initialParkingData: ParkingData = {
    "dailyIndoorSpace": null,
    "dailyIndoorSpaceRate": null,
    "dailyOutdoorSpace": null,
    "dailyOutdoorSpaceRate": null,
    "monthlyIndoorSpace": null,
    "monthlyIndoorSpaceRate": null,
    "monthlyOutdoorSpace": null,
    "monthlyOutdoorSpaceRate": null,
    "reservedIndoorSpace": null,
    "reservedIndoorSpaceRate": null,
    "reservedOutdoorSpace": null,
    "reservedOutdoorSpaceRate": null,
  }

  const initialPropertyData: PropertyData = {
    "officeOccupiedArea": null,
    "retailOccupiedArea": null,
    "warehouseStorageOccupiedArea": null,
    "manufactureOtherIndustrialUseOccupiedArea": null,
    "officeVacantArea": null,
    "retailVacantArea": null,
    "warehouseStorageVacantArea": null,
    "manufactureOtherIndustrialUseVacantArea": null,
    "officeVacantRate": null,
    "retailVacantRate": null,
    "warehouseStorageVacantRate": null,
    "manufactureOtherIndustrialUseVacantRate": null,
  }

  const parkingHeadings = [
    <th id='parking-vertical-header' className='pier-datatable-clear-th' key="parking-column-1-header"></th>,
    <th id='parking-indoor-spaces' className='pier-datatable-clear-th' key="parking-column-2-header">{t("parkingTableHeaderIndoor")}</th>,
    <th id='parking-rate-per-space' className='pier-datatable-clear-th' key="parking-column-3-header">{t("parkingTableHeaderRate")}</th>,
    <th id='parking-outdoor-spaces' className='pier-datatable-clear-th' key="parking-column-4-header">{t("parkingTableHeaderOutdoor")}</th>,
    <th id='parking-rate-per-space2' className='pier-datatable-clear-th' key="parking-column-5-header">{t("parkingTableHeaderRate")}</th>,
  ];

  const parkingDailyRow: TableCellData[] = [
    { name: "dailyIndoorSpace", type: "integer", header: "parking-indoor-spaces", forced: true, positiveOnly: true },
    { name: "dailyIndoorSpaceRate", type: "float", symbol: '$', header: "parking-rate-per-space", forced: true, positiveOnly: true },
    { name: "dailyOutdoorSpace", type: "integer", header: "parking-outdoor-spaces", forced: true, positiveOnly: true },
    { name: "dailyOutdoorSpaceRate", type: "float", symbol: '$', header: "parking-rate-per-space2", forced: true, positiveOnly: true },
  ]

  const parkingMonthlyRow: TableCellData[] = [
    { name: "monthlyIndoorSpace", type: "integer", header: "parking-indoor-spaces", forced: true, positiveOnly: true },
    { name: "monthlyIndoorSpaceRate", type: "float", symbol: '$', header: "parking-rate-per-space", forced: true, positiveOnly: true },
    { name: "monthlyOutdoorSpace", type: "integer", header: "parking-outdoor-spaces", forced: true, positiveOnly: true },
    { name: "monthlyOutdoorSpaceRate", type: "float", symbol: '$', header: "parking-rate-per-space2", forced: true, positiveOnly: true },
  ]

  const parkingReservedRow: TableCellData[] = [
    { name: "reservedIndoorSpace", type: "integer", header: "parking-indoor-spaces", forced: true, positiveOnly: true },
    { name: "reservedIndoorSpaceRate", type: "float", symbol: '$', header: "parking-rate-per-space", forced: true, positiveOnly: true },
    { name: "reservedOutdoorSpace", type: "integer", header: "parking-outdoor-spaces", forced: true, positiveOnly: true },
    { name: "reservedOutdoorSpaceRate", type: "float", symbol: '$', header: "parking-rate-per-space2", forced: true, positiveOnly: true },
  ]

  const propertyHeadings = [
    <th id='property-space-type' className='pier-datatable-clear-th' key="property-column-1-header">{t("propertyTableHeaderSpaceType")}</th>,
    <th id='property-office' className='pier-datatable-clear-th' key="property-column-2-header">{t("propertyTableHeaderOffice")}</th>,
    <th id='property-retail' className='pier-datatable-clear-th' key="property-column-3-header">{t("propertyTableHeaderRetail")}</th>,
    <th id='property-warehouse' className='pier-datatable-clear-th' key="property-column-4-header">{t("propertyTableHeaderWarehouse")}</th>,
    <th id='property-manufacturing' className='pier-datatable-clear-th' key="property-column-5-header">{t("propertyTableHeaderManufacturing")}</th>,
  ]

  const propertyVacantAskingRow: TableCellData[] = [
    { name: "officeVacantRate", type: "float", symbol: '$', header: "property-office", forced: true, positiveOnly: true, placeholder: "($/sf)" },
    { name: "retailVacantRate", type: "float", symbol: '$', header: "property-retail", forced: true, positiveOnly: true, placeholder: "($/sf)" },
    { name: "warehouseStorageVacantRate", type: "float", symbol: '$', header: "property-warehouse", forced: true, positiveOnly: true, placeholder: "($/sf)" },
    { name: "manufactureOtherIndustrialUseVacantRate", type: "float", symbol: '$', header: "property-manufacturing", forced: true, positiveOnly: true, placeholder: "($/sf)" },
  ]

  const dispatch = useDispatch();
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
  const [parkingData, setParkingData] = useState<ParkingData>(initialParkingData as ParkingData)
  const [propertyData, setPropertyData] = useState<PropertyData>(initialPropertyData as PropertyData)
  const [hasErrors, setHasErrors] = useState(false);
  const [rowErrors, setRowErrors] = useState({
    parkingDaily: false,
    parkingMonthly: false,
    parkingReserved: false,
    propertyOccupied: false,
    propertyVacant: false,
    propertyVacantAsking: false
  });

  const handleErrorsChange = (row: keyof typeof rowErrors, hasErrorsInRow: boolean) => {
    setRowErrors(prevState => {
      if (prevState[row] !== hasErrorsInRow) {
        return {
          ...prevState,
          [row]: hasErrorsInRow
        };
      }
      return prevState
    })
  }

  useEffect(() => {
    dispatch(resetGetProfileAttributes())
    dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (profileAttributes.data && !profileAttributes.loading) {
      setParkingData({
        dailyIndoorSpace: profileAttributes.data.dailyIndoorSpace,
        dailyIndoorSpaceRate: profileAttributes.data.dailyIndoorSpaceRate,
        dailyOutdoorSpace: profileAttributes.data.dailyOutdoorSpace,
        dailyOutdoorSpaceRate: profileAttributes.data.dailyOutdoorSpaceRate,
        monthlyIndoorSpace: profileAttributes.data.monthlyIndoorSpace,
        monthlyIndoorSpaceRate: profileAttributes.data.monthlyIndoorSpaceRate,
        monthlyOutdoorSpace: profileAttributes.data.monthlyOutdoorSpace,
        monthlyOutdoorSpaceRate: profileAttributes.data.monthlyOutdoorSpaceRate,
        reservedIndoorSpace: profileAttributes.data.reservedIndoorSpace,
        reservedIndoorSpaceRate: profileAttributes.data.reservedIndoorSpaceRate,
        reservedOutdoorSpace: profileAttributes.data.reservedOutdoorSpace,
        reservedOutdoorSpaceRate: profileAttributes.data.reservedOutdoorSpaceRate,
      })

      setPropertyData({
        officeOccupiedArea: profileAttributes.data.officeOccupiedArea,
        retailOccupiedArea: profileAttributes.data.retailOccupiedArea,
        warehouseStorageOccupiedArea: profileAttributes.data.warehouseStorageOccupiedArea,
        manufactureOtherIndustrialUseOccupiedArea: profileAttributes.data.manufactureOtherIndustrialUseOccupiedArea,
        officeVacantArea: profileAttributes.data.officeVacantArea,
        retailVacantArea: profileAttributes.data.retailVacantArea,
        warehouseStorageVacantArea: profileAttributes.data.warehouseStorageVacantArea,
        manufactureOtherIndustrialUseVacantArea: profileAttributes.data.manufactureOtherIndustrialUseVacantArea,
        officeVacantRate: profileAttributes.data.officeVacantRate,
        retailVacantRate: profileAttributes.data.retailVacantRate,
        warehouseStorageVacantRate: profileAttributes.data.warehouseStorageVacantRate,
        manufactureOtherIndustrialUseVacantRate: profileAttributes.data.manufactureOtherIndustrialUseVacantRate,
      })
    }
  }, [profileAttributes.data, profileAttributes.loading])

  useEffect(() => {
    const hasErrors = Object.values(rowErrors).some(error => error === true)
    setHasErrors(hasErrors)
  }, [rowErrors])

  // if(profileAttributes.data) {
  //   for(const key in initialPropertyData) {
  //       if(initialPropertyData[key as keyof CommercialPropertyData] === null) {
  //         initialPropertyData[key as keyof CommercialPropertyData] = profileAttributes.data[key as keyof CommercialPropertyData]
  //       }
  //   }
  // }

  const handleParkingChange = (name: keyof ParkingData, value: string | boolean | number | null) => {
    setParkingData(oldParkingData => ({
      ...oldParkingData,
      [name]: value
    }))
  }

  const sumParkingKeys = (keyToMatch: string): number => {
    const keys = Object.keys(parkingData) as (keyof ParkingData)[]

    let sum = 0
    keys.forEach((k) => {
      if (k && k.endsWith(keyToMatch) && (typeof parkingData[k] === 'string' || typeof parkingData[k] === 'number')) {
        const value = parseFloat(String(parkingData[k]))
        if (!isNaN(value)) {
          sum += value
        }
      }
    })

    return sum
  }

  const handlePropertyChange = (name: keyof PropertyData, value: string | boolean | number | null) => {
    setPropertyData(oldParkingData => ({
      ...oldParkingData,
      [name]: value
    }))
  }

  useEffect(() => {
    if (saveData.saving) {
      if (hasErrors) {
        toast.error("Error going to next page: Values must be positive.")
        dispatch(resetSaving())
        dispatch(setNextActiveMenuItem(initialMenuItem))
        return
      }

      const data = { ...profileAttributes.data, ...parkingData, ...propertyData } as Record<string, unknown>
      dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string), data))
    }
  }, [dispatch, hasErrors, parkingData, profileAttributes.data, profileOverview.profile.profile_type_code, propertyData, saveData.saving])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
    }
  }, [dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

  return (
      <div className='partial-width'>
        <div className='parking-section'>
          <h3>{t("parkingHeader")}</h3>
          <DataTable
            title={t("parkingTableTitle")}
            headings={parkingHeadings}>
            <>
              <TableRow rowLabelHeader='parking-vertical-header' rowLabel={t("parkingTableRowDaily")} cellData={parkingDailyRow} dataType={parkingData} onChange={handleParkingChange} onErrorsChange={(hasErrors) => handleErrorsChange('parkingDaily', hasErrors)} />
              <TableRow rowLabelHeader='parking-vertical-header' rowLabel={t("parkingTableRowMonthly")} cellData={parkingMonthlyRow} dataType={parkingData} onChange={handleParkingChange} onErrorsChange={(hasErrors) => handleErrorsChange('parkingMonthly', hasErrors)} />
              <TableRow rowLabelHeader='parking-vertical-header' rowLabel={t("parkingTableRowReserved")} cellData={parkingReservedRow} dataType={parkingData} onChange={handleParkingChange} onErrorsChange={(hasErrors) => handleErrorsChange('parkingReserved', hasErrors)} />
              <tr id='parking-sum-row' className='parking-sum-row'>
                <RowKey headers='parking-sum-row'>{t("parkingTableTotal")}</RowKey>
                <td headers='parking-sum-row'>{sumParkingKeys("IndoorSpace")}</td>
                <td headers='parking-sum-row'></td>
                <td headers='parking-sum-row'>{sumParkingKeys("OutdoorSpace")}</td>
                <td headers='parking-sum-row'></td>
              </tr>
            </>
          </DataTable>
        </div>
        <div className='property-section'>
          <h3>{t("propertyHeader")}</h3>
          <DataTable
            title={t("propertyTableTitle")}
            headings={propertyHeadings}>
              <TableRow rowLabelHeader='property-space-type' rowLabel={t("propertyTableRowVacantAsking")} cellData={propertyVacantAskingRow} dataType={propertyData} onChange={handlePropertyChange} onErrorsChange={(hasErrors) => handleErrorsChange('propertyVacantAsking', hasErrors)} />
          </DataTable>
        </div>
      </div>
  )
}

export default ParkingPropertySummary