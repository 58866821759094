import React, { useEffect, useState } from 'react'
import './ResidentialParking.css'
import { useTranslation } from 'react-i18next'
import DataTable from '../../../components/common/DataTable/DataTable'
import TableRow, { TableCellData } from '../../../components/common/TableRow/TableRow'
import { useDispatch, useSelector } from 'react-redux'
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer'
import { RootState } from '../../../redux/reducers/root'
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile'
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer'
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer'
import updateProperties from '../../../utils/DataModifier/DataModifier'
import Asterisk from '../../../components/common/Asterisk/Asterisk'

interface DataType {
    residentialSurfaceParkingNotInRent: number | null,
    residentialSurfaceParkingMonthlyRate: number | null,
    residentialSurfaceParkingInRent: number | null,
    residentialIndoorParkingNotInRent: number | null,
    residentialIndoorParkingMonthlyRate: number | null,
    residentialIndoorParkingInRent: number | null,
}
const initialData: DataType = {
    'residentialSurfaceParkingNotInRent': null,
    'residentialSurfaceParkingMonthlyRate': null,
    'residentialSurfaceParkingInRent': null,
    'residentialIndoorParkingNotInRent': null,
    'residentialIndoorParkingMonthlyRate': null,
    'residentialIndoorParkingInRent': null,
}

function ResidentialParking() {
    const { t } = useTranslation('residential_parking')
    const dispatch = useDispatch()
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)
    const [data, setData] = useState<DataType>(initialData)

    useEffect(() => {
        dispatch(resetGetProfileAttributes())
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!profileAttributes.loading && profileAttributes.data) {
            const initData = { ...initialData }
            updateProperties(initData, profileAttributes.data)
            setData(initData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAttributes.data, profileAttributes.loading])

    const parkingHeadings = [
        <th id='parking-vertical-header' key="parking-column-1-header" className='pier-datatable-centered-th'></th>,
        <th id='parking-total-spaces' key="parking-column-2-header" className='pier-datatable-centered-th'>{t("parkingHeaderTotalSpaceNotIncl")} <Asterisk /></th>,
        <th id='parking-monthly-rate' key="parking-column-3-header" className='pier-datatable-centered-th'>{t("parkingHeaderMonthly")} <Asterisk /></th>,
        <th id='parking-total-spaces-incl' key="parking-column-4-header" className='pier-datatable-centered-th'>{t("parkingHeaderTotalSpaceIncl")} <Asterisk /></th>,
        <th id='parking-total-spaces-sum' key="parking-column-5-header" className='pier-datatable-centered-th'>{t("parkingHeaderTotalSpaces")}</th>,
    ]

    const surface: TableCellData[] = [
        { name: "residentialSurfaceParkingNotInRent", type: "integer", header: "surface-total", forced: true, positiveOnly: true },
        { name: "residentialSurfaceParkingMonthlyRate", type: "float", symbol: '$', header: "surface-monthly", forced: true, positiveOnly: true },
        { name: "residentialSurfaceParkingInRent", type: "integer", header: "surface-total-incl", forced: true, positiveOnly: true },
    ]

    const indoor: TableCellData[] = [
        { name: "residentialIndoorParkingNotInRent", type: "integer", header: "indoor-total", forced: true, positiveOnly: true },
        { name: "residentialIndoorParkingMonthlyRate", type: "float", symbol: '$', header: "indoor-monthly", forced: true, positiveOnly: true },
        { name: "residentialIndoorParkingInRent", type: "integer", header: "indoor-total-incl", forced: true, positiveOnly: true },
    ]

    useEffect(() => {
        if (saveData.saving) {
            const fulldata = { ...profileAttributes.data, ...data } as Record<string, unknown>
            dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, fulldata))
        }
    }, [currentSnapshotId, data, dispatch, profileAttributes.data, profileOverview.profile.profile_type_code, saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        }
    }, [saveResultState, dispatch, profileOverview.profile.profile_type_code, currentSnapshotId])

    const handleResidentialParkingChange = (name: string, value: string | number | boolean | null) => {
        if (typeof value === "string") {
            value = parseFloat(value)
        }
        setData({
            ...data,
            [name]: value
        })
    }

    const sumParkingKeys = (keyToMatch: string): number => {
        const keys = Object.keys(data)

        let sum = 0
        keys.forEach((k) => {
            if (k && k.includes(keyToMatch) && k.includes("Rent") && (typeof data[k as keyof DataType] === 'number')) {
                sum += data[k as keyof DataType] as number
            }
        })
        return sum
    }

    return (
        <>
        <div className='partial-width'>
            <h3>{t("residentialParkingHeader")}</h3>
                <DataTable headings={parkingHeadings}>
                    <>
                        <TableRow rowLabel={t("parkingRowHeaderSuface")} cellData={surface} dataType={data} onChange={handleResidentialParkingChange}>
                            <td headers='total-spaces' style={{ textAlign: 'center' }} className='full-input-data'>{sumParkingKeys("residentialSurfaceParking")}</td>
                        </TableRow>
                        <TableRow rowLabel={t("parkingRowHeaderIndoor")} cellData={indoor} dataType={data} onChange={handleResidentialParkingChange}>
                            <td headers='total-spaces' style={{ textAlign: 'center' }} className='full-input-data'>{sumParkingKeys("residentialIndoorParking")}</td>
                        </TableRow>
                    </>
                </DataTable>
                </div>
        </>
    )
}

export default ResidentialParking