import React, { ReactElement, useEffect, useState } from "react";
import { LookupsState } from "../../../redux/reducers/getLookups/lookupsReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { useTranslation } from "react-i18next";
import { getLookups } from "../../../redux/actions/lookups/lookups";
import { BaseAsyncReducerState } from "../../../redux/types";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from "../../../redux/actions/incomeAttributes/incomeAttributes";
import CurrencyInput from "../../../components/common/CurrencyInput/CurrencyInput";
import ToolTip from "../../../components/common/ToolTip/ToolTip";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { SaveResultState } from "../../../redux/reducers/getSaveData/saveResultReducer";
import Total from "../../../components/common/Total/Total";
import MenuState from "../../../types/menu/MenuState";
import "../PIE.css";

function ReserveForReplacementAllowance(): ReactElement {
  const { t } = useTranslation(['pie']);
  const dispatch = useDispatch();

  const incomeAttributesState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const lookupState: LookupsState = useSelector((currentState: RootState) => (currentState.lookupsReducer.getLookups as LookupsState));
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const profileOverview: ProfileOverviewState = useSelector((currentState: RootState) => (currentState.profileReducer.getProfileOverview))
  const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
  const [totalExpense, setTotalExpense] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!incomeAttributesState.loading && !incomeAttributesState.success) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!lookupState.success) {
      dispatch(getLookups(lookupType))
    }
  }, [dispatch, lookupState.success, lookupType]);

  const findAttributeData = (attributeCode: string) => {
    let incomeAttributesData: Record<string, unknown> | undefined = undefined;
    if (incomeAttributesState.data) {
      incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
    }
    return incomeAttributesData;
  };

  const calcTotalExpense = () => {
    let calculated = false;
    let total = 0;

    const keys = Object.keys(lookupState.lookups[4]);
    keys.forEach((key) => {
      const lookupAttributes = lookupState.lookups[4][key];
      lookupAttributes.forEach((attribute) => {
        if (attribute.activeFlag === "Y") {
          const code = attribute.incomeAttributeCode;
          const incomeAttributesData = findAttributeData(code);
          if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
            total = total + (incomeAttributesData.value as number);
            calculated = true;
          }
        }
      });
    });
    if (calculated) {
      setTotalExpense(total);
    }
  }

  useEffect(() => {

    if (lookupState.success && incomeAttributesState.success) {
      setTimeout(() => {
        if (totalExpense === undefined) {
          calcTotalExpense();
        }
      }, 50);
    }
    // eslint-disable-next-line
  }, [incomeAttributesState.data, incomeAttributesState.success, lookupState.lookups, lookupState.success, lookupType, totalExpense]);

  const handleCommentChange = (value: string, name: string) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const v = value.trim() === "" ? null : value.trim();
      if (incomeAttributesData) {
        incomeAttributesData["description"] = v;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: v,
          value: null,
          percent: null,
          year: null,
          type: "4",
        });
      }
    }
  }

  const handleValueChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const na = newAmt === undefined ? null : newAmt;
      if (incomeAttributesData) {
        incomeAttributesData["value"] = na;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: null,
          value: na,
          percent: null,
          year: null,
          type: code === "50" ? "2" : "4",
        });
      }
    }

    calcTotalExpense()
  }

  useEffect(() => {
    if (saveData.saving) {
      const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
      dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes, menuState.activeItem.name));
    }
  }, [dispatch, incomeAttributesState.data, menuState.activeItem.name, saveData.saving]);

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
  }, [saveResultState, dispatch]);

  return (
    <>
      <div className="partial-width">
        {(!incomeAttributesState.loading && !lookupState.loading) &&
          <>
            <table className="table-with-border pie-table">
              <tbody>
                <tr>
                  <td className="padding-left-10 padding-right-10">
                    <ToolTip placement={"bottom-start"} contents={"Reserve For Replacement Allowance"}>
                      {t("tooltip.HTL.50")}
                    </ToolTip>
                  </td>
                  <td>
                    <CurrencyInput name={"income-attribute-comment-50"}
                      onChange={handleValueChange}
                      value={findAttributeData("50") === undefined ? undefined : (findAttributeData("50") as Record<string, unknown>)["value"] as number}
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>
                  </th>
                </tr>
                <tr>
                  <th className="padding-left-10 padding-right-10">
                    <ToolTip contents={("Replacement spending on short lived items")}>
                      {t("tooltip.HTL.50")}
                    </ToolTip>
                  </th>
                  <th className="padding-left-10 padding-right-10">
                    Amount
                  </th>
                </tr>
                {lookupState.lookups[4]["reserve"] && (lookupState.lookups[4]["reserve"].map((attr, key) => {
                  let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                  if (incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) {
                    incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attr.incomeAttributeCode);
                  }
                  if (attr.incomeAttributeCode === "114") {
                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                      data={incomeAttributesData} hasInputLabel={true} isPlaceHolderWithAttrDesc={true}
                      valueChangeHandler={handleValueChange}
                      handleCommentChange={handleCommentChange} isRequiredField={false} isCapitalCostsOrReplacementReserve={true}
                      showSubheading={false} isOtherLabel={false} />

                  } else {
                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                      data={incomeAttributesData} hasInputLabel={false} valueChangeHandler={handleValueChange}
                      handleCommentChange={handleCommentChange} isRequiredField={false} isCapitalCostsOrReplacementReserve={true}
                      showSubheading={false} isOtherLabel={false} />
                  }
                }
                )
                )
                }
              </tbody>
            </table>
            <div className='total-container'>
              <Total text={t('reserve.Total')} value={totalExpense} />
            </div>
          </>
        }
      </div>
    </>)
}

export default ReserveForReplacementAllowance;