import { faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "../../../types/menu/MenuItem"

const HTLNavList: MenuItem[] = [
  {
    id: 1,
    name: 'contactInfo',
    componentName: 'ContactInfo',
    complete: false,
  },
  {
    id: 2,
    name: 'incomeExpense',
    componentName: 'IncomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 21,
        name: 'roomStatistics',
        componentName: 'RoomStatistics',
        complete: false
      },
      {
        id: 22,
        name: 'departmentalRevenues',
        hasTabs: false,
        complete: false,
        componentName: 'RevenueContainer'
      },
      {
        id: 23,
        name: 'expenses',
        hasTabs: true,
        complete: false,
        componentName: 'ExpenseContainer',
        subItems: [
          {
            id: 231,
            name: 'departmentalExpenses',
            complete: false
          },
          {
            id: 232,
            name: 'undistributedExpenses',
            complete: false
          },
          {
            id: 233,
            name: 'nonOperatingIncomeRevenues',
            complete: false
          }
        ]
      },
      {
        id: 24,
        name: 'reserveForReplacementAllowance',
        complete: false,
        componentName: 'ReserveForReplacementAllowance'
      },
      {
        id: 25,
        name: 'capitalCostsSummary',
        componentName: 'CapitalCostsSummary',
        complete: false
      },
      {
        id: 26,
        name: 'comments',
        complete: false,
        componentName: 'Comment'
      }
    ],
  },
  {
    id: 3,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      {
        id: 31,
        name: 'leaseInformationTable',
        componentName: 'LeaseInfo',
        complete: false
      },
      {
        id: 32,
        name: 'parkingPropertySummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 4,
    name: 'summaryReview',
    componentName: 'Summary',
    complete: false,
    icon: faFilePdf
  },
  {
    id: 5,
    name: 'submit',
    componentName: 'Submit',
    complete: false,
    icon: faFileInvoiceDollar
  }
]

export default HTLNavList