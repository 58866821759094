import React from 'react';
import "../GuidedExperience/GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';

interface MultiresSummaryWizardProps {
    parkings: Record<string, unknown>;
    calculteFinalSuites: () => Record<string, unknown>;
}

export default function MultiresSummaryWizard(props: MultiresSummaryWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const currencySymbol = (globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"] as string;

    return (<div>
        <p>{t("multires-summary-wizard.multires-summary-description-1") + (props.calculteFinalSuites().total as number) + t("multires-summary-wizard.multires-summary-description-2")}</p>
        <table className='wizard-table'>
            <thead>
                <tr>
                    <th></th>
                    <th>{t("apartment-headings.spaces-not-in-rent")}</th>
                    <th>{t("apartment-headings.monthly-rate")}</th>
                    <th>{t("apartment-headings.spaces-in-rent")}</th>
                    <th>{t("apartment-headings.total-spaces")}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>{t("apartment-headings.surface")}</b></td>
                    <td className='no-input-cell'>{props.parkings["residentialSurfaceParkingNotInRent"] as string}</td>
                    <td className='no-input-cell'>{props.parkings["residentialSurfaceParkingMonthlyRate"] ? currencySymbol + (props.parkings["residentialSurfaceParkingMonthlyRate"] as string) : ""}</td>
                    <td className='no-input-cell'>{props.parkings["residentialSurfaceParkingInRent"] as string}</td>
                    <td className='no-input-cell'>{props.parkings["residentialSurfaceParkingTotal"] as string}</td>
                </tr>
                <tr>
                    <td><b>{t("apartment-headings.indoor")}</b></td>
                    <td className='no-input-cell'>{props.parkings["residentialIndoorParkingNotInRent"] as string}</td>
                    <td className='no-input-cell'>{props.parkings["residentialIndoorParkingMonthlyRate"] ? currencySymbol + (props.parkings["residentialIndoorParkingMonthlyRate"] as string) : ""}</td>
                    <td className='no-input-cell'>{props.parkings["residentialIndoorParkingInRent"] as string}</td>
                    <td className='no-input-cell'>{props.parkings["residentialIndoorParkingTotal"] as string}</td>
                </tr>
            </tbody>
        </table>
    </div>)
}