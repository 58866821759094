import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root'
import { SaveDataState } from '../../redux/reducers/getSaveData/saveDataReducer'
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer'
import RoundsRateData from '../../types/roundsRate/RoundsRateData'
import updateProperties from '../../utils/DataModifier/DataModifier'
import { useTranslation } from 'react-i18next'
import TableRow, { TableCellData } from '../../components/common/TableRow/TableRow'
import DataTable from '../../components/common/DataTable/DataTable'
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../redux/actions/profile/profile'
import { SaveResultState } from '../../redux/reducers/getSaveData/saveResultReducer'
import { ProfileAttributesState } from '../../redux/reducers/getProfile/profileAttributes/getAttributesReducer'

function RoundsRate() {
    const initialRoundsRateData: RoundsRateData = {
        "courseName1": null,
        "numberOfHole1": null,
        "primeRate1": null,
        "regularRound1": null,
        "memberRound1": null,
        "complimentaryRound1": null,
        "courseName2": null,
        "numberOfHole2": null,
        "primeRate2": null,
        "regularRound2": null,
        "memberRound2": null,
        "complimentaryRound2": null,
        "courseName3": null,
        "numberOfHole3": null,
        "primeRate3": null,
        "regularRound3": null,
        "memberRound3": null,
        "complimentaryRound3": null,
        "courseName4": null,
        "numberOfHole4": null,
        "primeRate4": null,
        "regularRound4": null,
        "memberRound4": null,
        "complimentaryRound4": null,
        "courseName5": null,
        "numberOfHole5": null,
        "primeRate5": null,
        "regularRound5": null,
        "memberRound5": null,
        "complimentaryRound5": null
    }

    const { t } = useTranslation('rounds_rate')
    const dispatch = useDispatch()
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const [roundsData, setRoundsData] = useState<RoundsRateData>(initialRoundsRateData as RoundsRateData)

    useEffect(() => {
        dispatch(resetGetProfileAttributes())
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!profileAttributes.loading && profileAttributes.data) {
            const initData = { ...initialRoundsRateData }
            updateProperties(initData, profileAttributes.data)
            setRoundsData(initData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAttributes.data, profileAttributes.loading])

    const headings = [
        <th title={t("roundsTitleCourseName")} id='course-name' className='pier-datatable-clear-th' key="rounds-column-1-header">{t("roundsCourseNameHeader")}</th>,
        <th title={t("roundsTitleNumHoles")} id='num-holes' className='pier-datatable-clear-th' key="rounds-column-2-header">{t("roundsNumHolesHeader")}</th>,
        <th title={t("roundsTitlePrimeRate")} id='prime-rate' className='pier-datatable-clear-th' key="rounds-column-3-header">{t("roundsPrimeRateHeader")}</th>,
        <th title={t("roundsTitleRegNumRounds")} id='reg-num-rounds' className='pier-datatable-clear-th' key="rounds-column-4-header">{t("roundsRegNumRoundsHeader")}</th>,
        <th title={t("roundsTitleMemberNumRounds")} id='mem-num-rounds' className='pier-datatable-clear-th' key="rounds-column-5-header">{t("roundsMemberNumRoundsHeader")}</th>,
        <th title={t("roundsTitleComplimentaryNumRounds")} id='comp-num-rounds' className='pier-datatable-clear-th' key="rounds-column-6-header">{t("roundsComplimentaryNumRoundsHeader")}</th>,
    ];

    const handleRoundsChange = (name: keyof RoundsRateData, value: string | boolean | number | null) => {
        setRoundsData(oldRoundsData => ({
            ...oldRoundsData,
            [name]: value
        }))
    }

    function generateCourseRows(): JSX.Element[] {
        const rows: JSX.Element[] = [];

        for (let i = 1; i <= 5; i++) {
            const courseRow: TableCellData[] = [
                { name: "courseName" + i, type: "text", header: "course-name", forced: true },
                { name: "numberOfHole" + i, type: "integer", header: "num-holes", forced: true, positiveOnly: true },
                { name: "primeRate" + i, type: "float", header: "prime-rate", forced: true, positiveOnly: true },
                { name: "regularRound" + i, type: "integer", header: "reg-num-rounds", forced: true, positiveOnly: true },
                { name: "memberRound" + i, type: "integer", header: "mem-num-rounds", forced: true, positiveOnly: true },
                { name: "complimentaryRound" + i, type: "integer", header: "comp-num-rounds", forced: true, positiveOnly: true },
            ]

            rows.push(<TableRow key={i} cellData={courseRow} dataType={roundsData} onChange={handleRoundsChange} />)
        }

        return rows
    }

    useEffect(() => {
        if (saveData.saving) {
            const data = { ...profileAttributes.data, ...roundsData } as Record<string, unknown>
            dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string), data))
        }
    }, [dispatch, profileAttributes.data, profileOverview.profile.profile_type_code, roundsData, saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
        }
    }, [dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

    return (
        <div className='partial-width'>
            <h3>{t("roundsTableHeader")}</h3>
            <DataTable
                title={t("roundsTableHeader")}
                headings={headings}>
                <>
                    {generateCourseRows()}
                </>
            </DataTable>
        </div>
    )
}

export default RoundsRate