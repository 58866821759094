import React from 'react';
import "../GuidedExperience/GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import Input from '../common/Input/Input';

interface NumberOfParkingsWizardProps {
    totalPropertyNameFromThePreviousStep?: string
    type: string;
    propertyName: string;
    parkings: Record<string, unknown>;
    updateParking: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function NumberOfParkingsWizard(props: NumberOfParkingsWizardProps): JSX.Element {
    const {totalPropertyNameFromThePreviousStep, type, propertyName, parkings, updateParking} = props;
    const { t } = useTranslation("guided_wizard");

    return (<div>
        <p>{t(`${type}-wizard.${type}-description-1`)}{totalPropertyNameFromThePreviousStep && <><b><u>{parkings[totalPropertyNameFromThePreviousStep] as number}</u></b>{t(`${type}-wizard.${type}-description-2`)}</>}</p>
        <Input type='text' id={type} name={propertyName} onChange={updateParking} 
            value={(parkings[propertyName] as number).toString()} />
    </div>)
}