import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import RadioButton from '../common/RadioGroup/RadioButton';
import ReactGA from 'react-ga4';

interface ConfirmImportWizardProps {
    setConfirmedImport: (value: boolean) => void;
    confirmedImport: undefined | boolean;
}

export default function ConfirmImportWizard(props: ConfirmImportWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const handleSelection = (value: boolean) => {
        // ReactGA event to track commercial wizard start
        ReactGA.event({
            category: 'Commercial Guided Wizard',
            action: 'Commercial Guided Wizard',
            label: `Import data: ${value}`,
        });
        console.log('Triggered CommWizard Import event')

        props.setConfirmedImport(value)
    }
    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("confirm-import-wizard.confirm-import-question")}</legend>
                <RadioButton name="confirm-import-question" id="confirm-import-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.confirmedImport ?? ""} onChange={() => handleSelection(true)} />
                <RadioButton name="confirm-import-question" id="confirm-import-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.confirmedImport ?? ""} onChange={() => handleSelection(false)} />
            </fieldset>
        </div>
    </div>)
}