import React, { useEffect, useState } from 'react';
import GEVInput from './GEVInput';
import GEVYesNo from './GEVYesNo';
import GEVTexttarea from './GEVTexttarea';
import GEVGrainLicenseType from './GEVGrainLicenseType';
import { OperationProps } from '../../types/gev/BusinessOperationFormProps';
import { FormError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import ErrorMessages from '../common/ErrorMessages/ErrorMessages';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';

export default function FeedMillingProcessingFacility(props: OperationProps): JSX.Element {
    // useEffect(() => {
    //     if (props.validating) {
    //         const err: Record<string, string> = {}
    //         const grainLicenseType = data["grainLicenseType"] as Record<string, boolean | null>
    //         if (grainLicenseType &&
    //             !Object.keys(grainLicenseType).some((option) => grainLicenseType[option] === true)) {
    //             err["grainLicenseType"] = t("missingRequiredField")
    //         }
    //         if (!data["licenseRegisteredTo"]) {
    //             err["licenseRegisteredTo"] = t("missingRequiredField")
    //         }
    //         if (data["licenseRegisteredTo"] && data["licenseRegisteredTo"].toString().length > 100) {
    //             err["licenseRegisteredTo"] = t("max-100-chars")
    //         }
    //         if (data["isLicenseInUse"] === null) {
    //             err["isLicenseInUse"] = t("missingRequiredField")
    //         }
    //         if (data["isCommercialGrainElevatorOperated"] === null) {
    //             err["isCommercialGrainElevatorOperated"] = t("missingRequiredField")
    //         }
    //         if (data["isTruckScaleOnSite"] === null) {
    //             err["isTruckScaleOnSite"] = t("missingRequiredField")
    //         }
    //         if (data["isTruckScaleOnSite"] && !data["truckScaleCapacity"]) {
    //             err["truckScaleCapacity"] = t("missingRequiredField")
    //         }
    //         if (data["truckScaleCapacity"] && data["truckScaleCapacity"].toString().length > 100) {
    //             err["truckScaleCapacity"] = t("max-100-chars")
    //         }
    //         if (data["areAnyBuildingsAddedOrRemoved"] === null) {
    //             err["areAnyBuildingsAddedOrRemoved"] = t("missingRequiredField")
    //         }
    //         if (data["areAnyBuildingsAddedOrRemoved"] && !data["comments"]) {
    //             err["comments"] = t("missingRequiredField")
    //         }
    //         if (data["comments"] && data["comments"].toString().length > 200) {
    //             err["comments"] = t("max-200-chars")
    //         }
    //         props.validate(operation)
    //         props.setData(operation, data)
    //         if (Object.keys(err).length) {
    //             props.setErrors(operation, err)
    //         }
    //     }
    // }, [data, props, t])
    const { errors } = useFormErrorContext();
    const [formErrors, setFormErrors] = useState<FormError[]>([]);
    const { operation, incrementErrCount } = props
    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey && error.primaryKey.split('.')[1] === operation) {
                    const splitKey = error.primaryKey.split('.');
                    splitKey.shift();
                    error.primaryKey = splitKey.join(".");
                    setFormErrors(prevData => [...prevData, error])
                    incrementErrCount(operation as BusinessOperationsKeys)
                }
            })
        }
        else {
            setFormErrors([]);
        }
    }, [errors, operation, incrementErrCount]);
    return (
        <div>
            <ErrorMessages errors={formErrors} />
            <form>
                <table width={'100%'} role="none" className='gev-table'>
                    <tbody>
                        <GEVGrainLicenseType
                            index={1}
                            operation={operation}
                            name={'grainLicenseType'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('grainLicenseType'))}
                        />
                        <GEVInput
                            index={2}
                            operation={operation}
                            name={'licenseRegisteredTo'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('licenseRegisteredTo'))}
                        />
                        <GEVYesNo
                            index={3}
                            operation={operation}
                            name={'isCommercialGrainElevatorOperated'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('isCommercialGrainElevatorOperated'))}
                        />
                        <GEVYesNo
                            index={4}
                            operation={operation}
                            name={'isLicenseInUse'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('isLicenseInUse'))}
                        />
                        <GEVYesNo
                            index={5}
                            operation={operation}
                            name={'isTruckScaleOnSite'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('isTruckScaleOnSite'))}
                        />
                        <GEVInput
                            operation={operation}
                            name={'truckScaleCapacity'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('truckScaleCapacity'))}
                        />
                        <GEVYesNo
                            index={6}
                            operation={operation}
                            name={'areAnyBuildingsAddedOrRemoved'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('areAnyBuildingsAddedOrRemoved'))}
                        />
                        <GEVTexttarea
                            operation={operation}
                            name={'comments'}
                            errors={props.errors}
                            hasErrors={formErrors.some(err => err.primaryKey.includes('comments'))}
                        />
                    </tbody>
                </table>
            </form>
        </div>
    )
}
