import React, { ReactElement } from "react";
import CurrencyInput from "../CurrencyInput/CurrencyInput";
import "./Total.css";

interface TotalProps {
    id?: string,
    text: string | ReactElement,
    value: number | undefined,
    className?: string,
    space?: boolean,
}

export default function Total(props: TotalProps): ReactElement {
    return (
        <table className='table-with-no-border pie-table' role="none">
            <tbody>
                {/* {
                    props.space !== false &&
                    <tr className="space">
                        <td className="label">
                            <label></label>
                        </td>
                        <td className="value">
                        </td>
                    </tr>
                } */}
                <tr className={props.className ? props.className : "default"}>
                    <td className="label">
                        <label>{props.text}</label>
                    </td>
                    <td className="value">
                        <CurrencyInput value={props.value} readOnly={true} id={props.id} />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

