import React from 'react';
import "../GuidedExperience/GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import Input from '../common/Input/Input';

interface OtherUnitsWizardProps {
    apartmentStyles: Record<string, unknown>[];
    multiresRowHouseUnitSubtype: Record<string, unknown>[];
    apartmentNumber: Record<string, unknown>;
    updateApartmentNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
    aptTypeMap: Record<string, string[]>;
}

export default function OtherUnitsWizard(props: OtherUnitsWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    return (<div>
        <p>{t("other-units-wizard.other-units-description")}</p>
        <table className='wizard-table'>
            <thead>
                <tr><th colSpan={props.multiresRowHouseUnitSubtype.length + 1}>{t("apartment-headings.suite-type")}</th></tr>
                <tr>
                    <th key={`other-units-header`}>{t("apartment-headings.style")}</th>
                    {props.multiresRowHouseUnitSubtype.map((subtype, index) => {
                        return <th key={`other-units-header-${index}`}>
                            <label htmlFor={`other-units-${props.apartmentStyles[0]["unitStyleCode"]}-${subtype["unitSubtypeCode"]}`}>
                                {subtype.unitSubtypeDescription as string}
                            </label>
                        </th>
                    })}
                </tr>
            </thead>
            <tbody>
                {props.apartmentStyles.filter((apartmentStyle) => apartmentStyle["unitStyleCode"] !== "1").map((apartmentStyle, i) =>(
                <tr key={`other-units-row-${i}`}>
                    <td key={`other-units-row-${i}-0`}>{apartmentStyle["unitStyleDescription"] as string}</td>
                    {props.multiresRowHouseUnitSubtype.map((subtype, index) => {
                        return <td key={`other-units-row-${i}-${index + 1}`}>
                            <Input type='text' id={`other-units-${apartmentStyle["unitStyleCode"]}-${subtype["unitSubtypeCode"]}`} 
                                name={`other-units-${apartmentStyle["unitStyleCode"]}-${subtype["unitSubtypeCode"]}`} onChange={props.updateApartmentNumber} 
                                value={((props.apartmentNumber[apartmentStyle["unitStyleCode"] as string] as Record<string, unknown>)[subtype["unitSubtypeCode"] as string] as number).toString()} />
                        </td>
                    })}
                </tr>
                ))}
            </tbody>
        </table>
    </div>)
}