import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { AccordionItem as Item, AccordionItemProps as Props } from '@szhsin/react-accordion';
import { ItemKey } from "@szhsin/react-accordion/types/utils/constants";
import { TransitionState } from "react-transition-state";

export const DefaultAccordionProviderOptions = {
    allowMultiple: true,
    transition: false,
    transitionTimeout: 250,
    initialEntered: true,
    onStateChange: (event: { key: ItemKey; current: TransitionState }) => { console.log(event) }
}

interface AccordionItemProps extends Omit<Props, "header"> {
    header: string | ReactElement,
}

export default function AccordionItem(props: AccordionItemProps): ReactElement {
    const { header, className, buttonProps, ...rest } = props
    const exitedIcon = <FontAwesomeIcon icon={faPlus} /> // <FontAwesomeIcon icon={faChevronDown} />
    const enteredIcon = <FontAwesomeIcon icon={faMinus} /> // <FontAwesomeIcon icon={faChevronUp} />
    return (
        <Item
            {...rest}
            header={({ state }) =>
                <>
                    <div className='accordion-header'>
                        <div>{props.header}</div>
                        <div>{state.status.toLowerCase().includes("exit") ?
                            exitedIcon
                            :
                            enteredIcon
                        }</div>
                    </div>
                </>
            }
            className={'accordion-container'}
            buttonProps={{ className: 'accordion-header-btn' }}
        />
    )

}

