import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenuItem, setMenu, setNextActiveMenuItem } from "../../redux/actions/menu/menu";
import { EXTERNAL_IN_PROGRESS } from "../../redux/actionTypes/actionsTypes";
import { RootState } from "../../redux/reducers/root";
import { GlobalSettingState } from "../../redux/reducers/getGlobalSetting/getGlobalSettingReducer";
import { initialMenuItem } from "../../redux/reducers/getMenu/getMenuReducer";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";
import CIXNavList from "../../assets/data/navigationList/CIXNavList";
import GLFNavList from "../../assets/data/navigationList/GLFNavList";
import HTLNavList from "../../assets/data/navigationList/HTLNavList";
import MTLNavList from "../../assets/data/navigationList/MTLNavList";
import LLENavList from "../../assets/data/navigationList/LLENavList";
import LTCNavList from "../../assets/data/navigationList/LTCNavList";
import RHONavList from "../../assets/data/navigationList/RHONavList";
import GEVNavList from "../../assets/data/navigationList/GEVNavList";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import MenuItem from "../../types/menu/MenuItem";
import MenuState from "../../types/menu/MenuState";
import './PortalMenu.css';
import { useReadOnly } from "../../utils/ReadOnlyContext";
import { resetSaveData, resetSaveResult, setSaving } from "../../redux/actions/saveData/saveData";
import { SaveResultState } from "../../redux/reducers/getSaveData/saveResultReducer";
import { toast } from "react-toastify";

function PortalMenu(): JSX.Element {
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const [collapsed, setCollapsed] = useState(false)
    const dispatch = useDispatch();
    const { isReadOnly } = useReadOnly()
    const propertyCode = localStorage.getItem("propertyCode") as string

    useEffect(() => {

        const isWIP = () => {
            let isWIP = false
            const campaignId = localStorage.getItem('campaignId')
            if (campaignId) {
                const campaigns = [profileOverview.profile.current_campaign].concat(profileOverview.profile.previous_campaigns)
                campaigns.forEach((campaign) => {
                    if (String(campaign.campaignId) === campaignId
                        && campaign.statusCode === EXTERNAL_IN_PROGRESS) {
                        isWIP = true
                    }
                })
            }
            return isWIP
        }

        const setDynamicMenu = (profileType: string): void => {
            let menuList: MenuItem[] = []
            switch (profileType) {
                case 'BIP':
                    menuList = structuredClone(CIXNavList)
                    break;
                case 'GLF':
                    menuList = structuredClone(GLFNavList)
                    break;
                case 'HTL':
                    menuList = structuredClone(HTLNavList)
                    break;
                case 'MTL':
                    menuList = structuredClone(MTLNavList)
                    break;
                case 'LLE':
                    menuList = structuredClone(LLENavList)
                    break;
                case 'LTC':
                    menuList = structuredClone(LTCNavList)
                    break;
                case 'RHO':
                    menuList = structuredClone(RHONavList)
                    break;
                case 'GEV':
                    menuList = structuredClone(GEVNavList)
                    break;
                default:
                    menuList = []
            }
            // Only show submit menu item for WIP status
            if (!isWIP()) {
                menuList = menuList.filter((menu) => menu.name !== "submit")
            }
            if (isReadOnly) {
                menuList = menuList.filter(item => item.name !== "submit" && item.name !== "summaryReview")
            }
            // For Multi-Res and Mixed Use, add Utilities and ResidentialParking menu items
            if (["340", "341", "352", "361", "602", "470", "472", "478"].includes(propertyCode)) {
                const apartmentItem = menuList.find((menu) => menu.name === "apartment")
                const commercialItem = menuList.find((menu) => menu.name === "commercial")
                // Show Apartment before Commercial in navigation
                if (propertyCode !== "602" && commercialItem && apartmentItem && apartmentItem.id > commercialItem.id) {
                    const tempList = [...menuList]
                    const tempComItem = { ...commercialItem }
                    commercialItem.id = apartmentItem.id
                    tempList[apartmentItem.id - 1] = commercialItem
                    apartmentItem.id = tempComItem.id
                    tempList[tempComItem.id - 1] = apartmentItem
                    menuList = tempList
                }
                if (apartmentItem) {
                    if (apartmentItem.subItems) {
                        const utilitiesItem = apartmentItem.subItems.find((menu) => menu.name === "utilities")
                        const resParkingItem = apartmentItem.subItems.find((menu) => menu.name === "residentialParking")
                        const otherItems = apartmentItem.subItems.filter((menu) => menu.name !== "utilities" && menu.name !== "residentialParking")
                        let baseId = apartmentItem.id * 10
                        if (otherItems) {
                            const len = otherItems.length
                            const currentLastSubitem = apartmentItem.subItems[len - 1]
                            baseId = currentLastSubitem.id
                        }
                        const toBeAdded = [] as MenuItem[]
                        if (!utilitiesItem) {
                            const newUtilitiesItem = {
                                id: baseId + 1,
                                name: 'utilities',
                                componentName: 'Utilities',
                                complete: false
                            }
                            toBeAdded.push(newUtilitiesItem)
                        }
                        if (!resParkingItem) {
                            const newResParkingItem = {
                                id: baseId + 2,
                                name: 'residentialParking',
                                componentName: 'ResidentialParking',
                                complete: false,
                            }
                            toBeAdded.push(newResParkingItem)
                        }
                        apartmentItem.subItems = otherItems.concat(toBeAdded)
                    }
                }
            // Show Commercial before Apartment in navigation
            } else if (["402", "403", "409", "417", "419", "425", "426", "427", "428", "429", "430", "431", "433", "434", "435", "436", "438", "481", "483", "580"].includes(propertyCode)) {
                const apartmentItem = menuList.find((menu) => menu.name === "apartment")
                const commercialItem = menuList.find((menu) => menu.name === "commercial")
                if (commercialItem && apartmentItem && commercialItem.id > apartmentItem.id) {
                    const tempList = [...menuList]
                    const tempAptItem = { ...apartmentItem }
                    apartmentItem.id = commercialItem.id
                    tempList[commercialItem.id - 1] = apartmentItem
                    commercialItem.id = tempAptItem.id
                    tempList[tempAptItem.id - 1] = commercialItem
                    menuList = tempList
                }
            }

            dispatch(resetSaveData());
            dispatch(resetSaveResult());
            dispatch(setMenu(menuList))
            if (menuList.length > 0) {
                dispatch(setActiveMenuItem(menuList[0]))
            } else {
                dispatch(setActiveMenuItem(initialMenuItem))
            }
        }

        setDynamicMenu(profileOverview.profile.profile_type_code)
    }, [dispatch, isReadOnly, propertyCode, profileOverview.profile]);

    // useEffect(() => {
    //     const filterMenu = (menuList: MenuItem[]): MenuItem[] => {
    //         let result = menuList
    //         if (isReadOnly) {
    //             result = menuList.filter(item => item.name !== "submit" && item.name !== "summaryReview")
    //         }
    //         return result
    //     }

    //     if (!menuState.activeItem || menuState.activeItem.id === 0) {
    //         if (menuList.length > 0) {
    //             dispatch(setMenu(filterMenu(menuList)));
    //             dispatch(setActiveMenuItem(filterMenu(menuList)[0]));
    //             dispatch({ type: RESET_SAVEDATA });
    //         }
    //     } else {
    //         if (menuList.length > 0) {
    //             dispatch(setMenu(filterMenu(menuList)));
    //         }
    //     }
    // }, [dispatch, menuList, isReadOnly, menuState.activeItem]);

    const navigateToNextActiveMenu = () => {
        if (menuState.nextActiveItem.id) {
            dispatch(setActiveMenuItem(menuState.nextActiveItem));
        }
    }

    useEffect(() => {
        // When the nextActiveItem is different from the activeItem, the user is attempting to navigate to another page,
        // if there is no changesPending or is in isReadOnly mode, proceed to another page, otherwise trigger setSaving.
        // Once the data is successfully saved, the page component should resetSaveData, making changesPending false
        if (menuState.nextActiveItem.id !== 0 && (menuState.activeItem.id !== menuState.nextActiveItem.id
            || ((menuState.activeItem.selectedTabIndex ?? -1) !== (menuState.nextActiveItem.selectedTabIndex ?? -1)))) {
            if (isReadOnly) {
                navigateToNextActiveMenu();
            } else if (menuState.activeItem.id) {
                dispatch(setSaving());
            } else {
                navigateToNextActiveMenu();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isReadOnly, menuState.activeItem.id, menuState.activeItem.selectedTabIndex, menuState.nextActiveItem.id, menuState.nextActiveItem.selectedTabIndex]);

    // If saveResult status is 200, reset everything, otherwise show the error message, set saving to false, and clear setNextActiveMenuItem
    useEffect(() => {
        if (saveResultState.status) {
            if (saveResultState.status === 200) {
                navigateToNextActiveMenu();
            } else {
                dispatch(setNextActiveMenuItem(initialMenuItem));
                if (saveResultState.message) {
                    toast.error(saveResultState.message);
                }
            }
            dispatch(resetSaveData());
            dispatch(resetSaveResult());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveResultState, dispatch]);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    }

    return (<Sidebar
        collapsed={collapsed}
        handleCollapse={handleCollapsedChange}
        year={globalSettingState.success ? (globalSettingState.setting["campaign_year"] as string) : ""}
        items={menuState.menu}
    />
    )
}

export default PortalMenu;