import React, { ReactElement, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ReactDatePickerProps } from "react-datepicker";
import { parseISO } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import 'react-datepicker/dist/react-datepicker.css';
import "./DatePicker.css"
import { registerLocale } from "react-datepicker";
import { enUS, fr } from 'date-fns/locale';
import { useTranslation } from "react-i18next";
import { useReadOnly } from "../../../utils/ReadOnlyContext";
registerLocale('en', enUS)
registerLocale('fr', fr)

interface DatePickProps extends Omit<ReactDatePickerProps, "onChange"> {
    id: string,
    name: string,
    date?: Date | string | null | undefined,
    onChange?: (date: Date | null | undefined, name: string) => void,
    position: "top" | "bottom" | "right" | "left" | "auto",
    minDate?: Date,
    maxDate?: Date,
    errorMessage?: string | string[],
    disabled?: boolean,
    hasError?: boolean
}

export default function DatePicker(props: DatePickProps): ReactElement {
    const { i18n } = useTranslation();
    const { t } = useTranslation('common');
    const { isReadOnly } = useReadOnly()

    const initDate = () => {
        let date = undefined;
        if (props.date) {
            date = (typeof props.date === 'string') ? parseISO(props.date) : props.date
        } else if (props.selected) {
            date = props.selected;
        } else if (props.value) {
            date = parseISO(props.value);
        }
        return date;
    }

    const name = props.name;
    const [date, setDate] = useState<Date | null | undefined>(initDate())
    const [error, setError] = useState('')

    const handleDateChangeRaw = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        const currentTarget = e.currentTarget

        if (currentTarget.name === name) {
            // input value change
            let targetValue = currentTarget.value
            if (targetValue != null) {
                targetValue = targetValue.trim()
                if (targetValue.length === 0) {
                    setDate(undefined);
                    setError("")
                    if (props.onChange) {
                        props.onChange(undefined, name)
                    }
                } else if (targetValue.length === 10) {
                    try {
                        const dt = parseISO(targetValue.trim())
                        if (dt instanceof Date && !isNaN(dt.getTime())) {
                            setDate(dt)
                            setError("")
                            if (props.onChange) {
                                props.onChange(dt, name)
                            }
                        } else {
                            setError("Invalid Date")
                        }
                    } catch (error) {
                        console.log("error", error)
                    }
                } else {
                    setError("Invalid Date")
                }
            }
        }
    }

    const dateSelected = (date: Date | null | undefined) => {
        setError('');
        setDate(date);
        if (props.onChange) {
            props.onChange(date, name);
        }
    }

    return (
        <div className="datepicker-wrapper">
            <div id={props.id + "-container"} className={'datepicker-container'}>
                <ReactDatePicker {...props} id={props.id + "-input"} name={name} dateFormat="yyyy-MM-dd"
                    className={`${props.className} datepicker-input ${(props.hasError && 'form-error')}`}
                    placeholderText={props.placeholderText ? props.placeholderText : t("datepicker-placeholder")}
                    selected={date}
                    locale={i18n.language} closeOnScroll={false} showPopperArrow={false}
                    popperPlacement={props.position}
                    onChangeRaw={handleDateChangeRaw}
                    onSelect={(date) => dateSelected(date)}
                    onBlur={() => { if (error) dateSelected(date) }}
                    autoComplete="off"
                    onChange={() => null}
                    disabled={isReadOnly || props.disabled}
                    title={props.title}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={10}
                 />
                <FontAwesomeIcon icon={faCalendarDays} className="datepicker-icon"
                    onClick={() => document.getElementById(name + "-input")?.focus()} />
            </div>
            {(error || props.errorMessage) && (
                <pre id={props.id + "-error"} className="form-error-message">
                    {error === "" ? props.errorMessage : error}
                </pre>
            )}
        </div>
    )
}

