import React, { useEffect, useRef, useState } from 'react';
import { HashRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import { generateRoutes } from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/reducers/root';
import { resetValidateRefreshToken, validateInternalToken, validateRefreshToken, validateToken } from './redux/actions/auth/token';
import setSessionExpiry from './utils/sessionExpiry';
import { logout } from './redux/actions/auth/auth';
import { LogoutState } from './redux/reducers/getAuthReducer/logoutReducer';
import ValidateTokenState from './types/auth/ValidateTokenState';
import ValidateInternalTokenState from './types/auth/ValidateInternalTokenState';
import { toast } from 'react-toastify';
import { useValidationResponse } from './utils/context/FormErrorContext';
import { SaveResultState } from './redux/reducers/getSaveData/saveResultReducer';
import Modal from './components/common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import Button from './components/common/Button/Button';
import { ValidateRefreshTokenState } from './redux/reducers/getAuthReducer/validateRefreshTokenReducer';

function App(): JSX.Element {
	const dispatch = useDispatch()
	const [auth, setAuth] = useState(false);
	const [sessionExpired, setSessionExpired] = useState(false);
	const [requestSent, setRequestSent] = useState(false);
	const [internalReqsent, setInternalReqSent] = useState(false);
	const [isInternalUser, setIsInternalUser] = useState(false);
	const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
	const validateTokenStore = useSelector((state: RootState) => state.authReducer.validateToken as ValidateTokenState);
	const validateInternalTokenStore = useSelector((state: RootState) => state.authReducer.validateInternalToken as ValidateInternalTokenState);
	const logoutStore = useSelector((state: RootState) => state.authReducer.logout as LogoutState);
	const refreshTokenStore = useSelector((state: RootState) => state.authReducer.validateRefreshToken as ValidateRefreshTokenState);
	const internalUserVariable = process.env.REACT_APP_IS_INTERNAL_USER;
	const isLocalVariable = process.env.REACT_APP_IS_LOCAL;
	const [showSessionModal, setShowSessionModal] = useState<"hide" | "extend" | "expired">("hide");
	const [timerIsSet, setTimerIsSet] = useState(false);
	const { t } = useTranslation('system'); 
	const ExtendTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
	const ExpireTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

	useValidationResponse(saveResultState.result ?? []);
	useEffect(() => {
		console.log("Internal user variable: ", internalUserVariable);
		console.log("Is local variable: ", isLocalVariable);
		// eslint-disable-next-line
		if (internalUserVariable == "0") {
			// Validate amp token only for external users
			// eslint-disable-next-line
			if (isLocalVariable == "0") {
				const splitRoute = window.location.href.split('/')
				const localToken = window.localStorage.getItem("authToken")
				const alreadyHasToken = localToken !== null && setSessionExpiry() > 0
				const token = alreadyHasToken ? localToken : splitRoute[splitRoute.length - 1];
				console.log("External token", token);
				const refreshTokenArr = window.location.href.split("refresh=");
				if (refreshTokenArr.length === 2) {
					window.localStorage.setItem("refreshToken", refreshTokenArr[1]);
				}

				if(token) {
					const parsedToken = alreadyHasToken ? token : token.split('?')[0]
					dispatch(validateToken(parsedToken));
					setRequestSent(true);
				}
				else {
					toast.error("Not Authorized, redirecting...");
					window.location.href = `${process.env.REACT_APP_AMP_URL}#/auth/home/reg-user`;
				}
			}
			else {
				dispatch(validateToken(process.env.REACT_APP_LOCAL_TOKEN as string));
				setRequestSent(true);
			}
		}
		// eslint-disable-next-line
		else if (internalUserVariable == "1") {
			const splitRoute = window.location.href.split('/');
			const token = splitRoute[splitRoute.length - 2];
			const roll = splitRoute[splitRoute.length - 1];
			console.log("Internal token", token);
			console.log("Internal roll", roll);
			localStorage.setItem("rollNumber", roll);

			if (!internalReqsent) {
				dispatch(validateInternalToken(token));
				setInternalReqSent(true);
			}
		}
	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (logoutStore.requested) {
			setSessionExpired(true);
		}
	}, [logoutStore.requested]);

  useEffect(() => {
    if(requestSent) {
      if(validateTokenStore.valid) {
        validateTokenStore.token && window.localStorage.setItem("authToken", validateTokenStore.token);
        validateTokenStore.exp && window.localStorage.setItem("sessionExp", String(validateTokenStore.exp));
        setAuth(true);
        setRequestSent(false);
      }
      else if (validateTokenStore.error) {
        toast.error(validateTokenStore.message);
				// Don't redirect to amp in local environment
				// eslint-disable-next-line
				if (isLocalVariable == '0') {
					window.location.href = `${process.env.REACT_APP_AMP_URL}/#/auth/home/reg-user`;
				}
      }
    }
  }, [validateTokenStore, requestSent, isLocalVariable]);


	useEffect(() => {
    if(internalReqsent) {
			if (validateInternalTokenStore.success) {
				localStorage.setItem("authToken", validateInternalTokenStore.admin_user_token as string);
				localStorage.setItem("username", validateInternalTokenStore.username as string);
				localStorage.setItem("sessionExp", String(validateInternalTokenStore.exp))
				localStorage.setItem('isInternalUser', "true");
				setIsInternalUser(true);
				setAuth(true);
				setInternalReqSent(false);
			}
			else if (validateInternalTokenStore.error) {
				toast.error(validateInternalTokenStore.message as string);
				window.location.href = `${process.env.REACT_APP_INTERNAL_PORTAL_URL}#/login`;
			}
    }
  }, [validateInternalTokenStore, internalReqsent]);

  const setTimers = () => {
	const timeLeft = setSessionExpiry();
	ExtendTimer.current && clearTimeout(ExtendTimer.current)
	ExpireTimer.current && clearTimeout(ExpireTimer.current)
	if (timeLeft <= 0) {
		if (!sessionExpired) {
			setSessionExpired(true);
			toast.error("Session expired");
			logoutUser();
		}
		setShowSessionModal("expired");
	} else {
		if (sessionExpired) {
			setSessionExpired(false);
		}
		if (timeLeft > 300000) {
			ExtendTimer.current = setTimeout(() => {
				setShowSessionModal("extend");
			}, timeLeft - 300000);
		} else if (timeLeft <= 300000 && timeLeft > 0) {
			setShowSessionModal("extend");
		}
		ExpireTimer.current = setTimeout(() => {
			setSessionExpired(true);
			logoutUser();
			setShowSessionModal("expired");
		}, timeLeft );
	}
	setTimerIsSet(true);
  }

  useEffect(() => {
	if (refreshTokenStore.valid && auth && !sessionExpired) {
		refreshTokenStore.token && window.localStorage.setItem("authToken", refreshTokenStore.token);
        refreshTokenStore.exp && window.localStorage.setItem("sessionExp", String(refreshTokenStore.exp));
        refreshTokenStore.refresh && window.localStorage.setItem("refreshToken", refreshTokenStore.refresh);
		setTimers();
		dispatch(resetValidateRefreshToken());
	} else if (!refreshTokenStore.valid && refreshTokenStore.message && refreshTokenStore.message !== "") {
		toast.error(t("failed-extension") as string);
	}
	// eslint-disable-next-line
  }, [refreshTokenStore, auth, sessionExpired])

  useEffect(() => {
	// Session only in live environment
	// eslint-disable-next-line
	if (isLocalVariable== "0" && localStorage.getItem("sessionExp") !== undefined && validateTokenStore.valid && !timerIsSet) {
		setTimers()
	}
	// eslint-disable-next-line
  }, [timerIsSet, validateTokenStore.valid]);

	const logoutUser = () => {
		dispatch(logout())
		localStorage.clear();
		setAuth(false);
	}
	
	const extendSession = () => {
		const refreshToken = window.localStorage.getItem("refreshToken");
		if (refreshToken) {
			dispatch(validateRefreshToken(refreshToken))
		} else {
			toast.error(t("failed-extension") as string);
		}
		setShowSessionModal("hide");
	}

	const routes = generateRoutes(auth, sessionExpired);
	return (
		<>
			<main>
				<Router>
					<Routes>
						{routes}
						<Route path="*"
						element={auth && (isInternalUser ? <Navigate to={"/internal-landing"} /> : <Navigate to={"/landing"} />)} />
					</Routes>
				</Router>
				{showSessionModal !== "hide" && <Modal class="session-modal" onCloseModal={() => {setShowSessionModal("hide"); if (showSessionModal === "expired") {window.location.href = `${process.env.REACT_APP_AMP_URL}/#/auth/home/reg-user`;}}} >
					<div>
						<h3>{showSessionModal === "extend" ? t("expiring") : t("expired")}</h3>
						<p>{showSessionModal === "extend" ? t("extend-question") : t("login")}</p>
						<div className='session-modal-buttons'>
							{showSessionModal === "extend" && <Button type='secondary' onClick={() => extendSession()} >
								{t("extend")}
							</Button>}
							{showSessionModal === "extend" && <Button type='cancel' onClick={() => setShowSessionModal("hide")} >
								{t("cancel")}
							</Button>}
							{showSessionModal === "expired" && <Button type='secondary' onClick={() => {setShowSessionModal("hide"); window.location.href = `${process.env.REACT_APP_AMP_URL}/#/auth/home/reg-user`;}} >
								{t("ok")}
							</Button>}
						</div>
					</div>
				</Modal>}
			</main>
		</>
	)
}

export default App;