/**
 * Update: 18/04/2024 - Mariam Bawa - Refactor code to use IncomeAttributeTableFragmentGenerator to generate table content and cast data to IncomeAttributeLookupObject
*  Update: 18/04/2024 - Mariam Bawa - Added thead element in markup to meet AODA standards. 
Element is then hidden from UI using CSS
*/
import React from "react";
import IncomeAttributeLookupObject from "../../../types/lookups/IncomeAttributeLookupObject";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";

export interface CapitalCostProps {
  lookups: IncomeAttributeLookupObject[],
  name: string,
  incomeAttributes?: Record<string, unknown>[],
  valueChangeHandler?: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
}

export default function CapitalCostTab(props: CapitalCostProps): JSX.Element {

  return (
    <>
      {
        props.lookups &&
        <table className="table-with-border pie-table">
          <thead>
            <tr>
              <th id="attribute-label" scope="col"></th>
              <th id="attribute-value" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {
              props.lookups.map((attr, key) => {
                let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                if (props.incomeAttributes) {
                  incomeAttributesData = props.incomeAttributes.find(({ code }) => code === attr.incomeAttributeCode);
                }
                return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                  data={incomeAttributesData}
                  valueChangeHandler={props.valueChangeHandler}
                  handleCommentChange={() => null}
                  showSubheading={false}
                  hasInputLabel={false}
                  isOtherLabel={false}
                  isRequiredField={false}
                  isReplacementReserve={false}
                />
              }
              )
            }
          </tbody>
        </table>
      }
    </>
  );
}
