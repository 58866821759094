/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 * Update: 18/04/2024 - Mariam Bawa - Cast ELS Income attribute response to work with updated LookupsState
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from '../../../redux/actions/incomeAttributes/incomeAttributes';
import { getLookups } from '../../../redux/actions/lookups/lookups';
import { RootState } from '../../../redux/reducers/root';
import { IncomeAttributeState } from '../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer';
import { LookupsState } from '../../../redux/reducers/getLookups/lookupsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import MenuState from '../../../types/menu/MenuState';
import RevenueTab from './RevenueTab';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { ControlledAccordion, useAccordionProvider } from '@szhsin/react-accordion';
import AccordionItem, { DefaultAccordionProviderOptions } from '../../../components/common/AccordionItem/AccordionItem';
import Total from '../../../components/common/Total/Total';
import "../PIE.css";

export default function RevenueContainer(): JSX.Element {
  const noLossTypes = ['htl-income-attributes', 'ltc-income-attributes', 'lle-income-attributes', 'rho-income-attributes']
  const { t } = useTranslation(['navigation', 'pie']);
  const dispatch = useDispatch();
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const tabList = menuState.activeItem.subItems;

  const [totalIncome, setTotalIncome] = useState<number | undefined>(undefined);
  const [totalLoss, setTotalLoss] = useState<number | undefined>(undefined);
  const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);

  const providerValue = useAccordionProvider(DefaultAccordionProviderOptions)

  useEffect(() => {
    if ((!incomeAttributesState.loading && !incomeAttributesState.success)
      || incomeAttributesState.snapshotId !== Number(localStorage.getItem('currentSnapshotId') as string)) {
      dispatch(getIncomeAttributesBySnapshotId(localStorage.getItem('currentSnapshotId') as string));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (saveData.saving) {
      const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
      dispatch(putIncomeAttributesBySnapshotId(localStorage.getItem('currentSnapshotId') as string, incomeAttributes, menuState.activeItem.name));
    }
  }, [dispatch, incomeAttributesState.data, menuState.activeItem.name, saveData.saving]);

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getIncomeAttributesBySnapshotId(localStorage.getItem('currentSnapshotId') as string));
    }
  }, [dispatch, saveResultState]);

  const findAttributeData = (attributeCode: string) => {
    let incomeAttributesData: Record<string, unknown> | undefined = undefined;
    if (incomeAttributesState.data) {
      incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
    }
    return incomeAttributesData;
  }

  const handleTotalIncome = (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => {
    let calculated = false;
    let value = 0;
    const idx = name.lastIndexOf("-");
    let code = "";
    if (idx !== -1) {
      code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const na = newAmt ? newAmt : null;
      if (incomeAttributesData) {
        if (lookupType === "ltc-income-attributes" && code === "159") {
          incomeAttributesData["percent"] = na;
        } else {
          incomeAttributesData["value"] = na;
        }
      } else {
        if (lookupType === "ltc-income-attributes" && code === "159") {
          (incomeAttributesState.data as Record<string, unknown>[]).push({
            snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
            code: code,
            description: null,
            value: null,
            type: "1",
            percent: na,
            year: null,
          });
        } else {
          (incomeAttributesState.data as Record<string, unknown>[]).push({
            snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
            code: code,
            description: null,
            value: na,
            type: "1",
            percent: null,
            year: null,
          });
        }
      }
    }

    if (typeof totalIncome === "number") {
      value = totalIncome;
    }
    if (lookupType === "ltc-income-attributes" && code === "159") {
      if (typeof oldAmt === "number") {
        if (oldAmt / 100 === 1) {
          value = initTotalIncome("rentalLoss");
        } else {
          value = value / (1 - (oldAmt / 100));
        }
        calculated = true;
      }
      if (typeof newAmt === "number") {
        value = value - (value * newAmt / 100);
        calculated = true;
      }
    } else {
      const vacancyAttributesData = findAttributeData("159");
      if (typeof newAmt === "number") {
        if (vacancyAttributesData && vacancyAttributesData.percent) {
          value = value + newAmt - (newAmt * (vacancyAttributesData.percent as number) / 100);
        } else {
          value = value + newAmt;
        }
        calculated = true;
      }
      if (typeof oldAmt === "number") {
        if (vacancyAttributesData && vacancyAttributesData.percent) {
          value = value - oldAmt + (oldAmt * (vacancyAttributesData.percent as number) / 100);
        } else {
          value = value - oldAmt;
        }

        calculated = true;
      }
    }

    if (calculated) {
      setTotalIncome(value);
    }
  }

  const handleTotalLoss = (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => {
    let calculated = false;
    let value = 0;
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const na = newAmt ? newAmt : null;
      if (incomeAttributesData) {
        incomeAttributesData["value"] = na;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: null,
          value: na,
          type: "5",
          percent: null,
          year: null,
        });
      }
    }

    if (typeof totalLoss === "number") {
      value = totalLoss;
    }
    if (typeof newAmt === "number") {
      value = value + newAmt;
      calculated = true;
    }
    if (typeof oldAmt === "number") {
      value = value - oldAmt;
      calculated = true;
    }
    if (calculated) {
      setTotalLoss(value);
    }
  }

  const handleCommentChange = (value: string, name: string) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const v = value.trim() === "" ? null : value.trim();
      if (incomeAttributesData) {
        incomeAttributesData["description"] = v;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: v,
          value: null,
          type: "1",
          percent: null,
          year: null,
        });
      }
    }
  }

  const initTotalIncome = (lossTabName: string) => {
    let calculated = false;
    let total = 0;
    let vacancyPercent = 0;

    const keys = Object.keys(lookupState.lookups[1]);
    keys.forEach((key) => {
      if (key !== lossTabName) {
        const lookupAttributes = lookupState.lookups[1][key];
        lookupAttributes.forEach((attribute) => {
          if (attribute.activeFlag === "Y") {
            const code = attribute.incomeAttributeCode;
            const incomeAttributesData = findAttributeData(code);
            if (lookupType === "ltc-income-attributes" && code === "159") {
              if (incomeAttributesData && incomeAttributesData.percent && incomeAttributesData.percent !== 0) {
                vacancyPercent = (incomeAttributesData.percent as number);
              }
            } else {
              if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
                total = total + (incomeAttributesData.value as number);
                calculated = true;
              }
            }
          }
        });
      }
    });
    if (calculated) {
      if (vacancyPercent !== 0) {
        console.log(total)
        total = total - (total * vacancyPercent / 100);
        console.log(total)
      }
      setTotalIncome(total);
    }
    return total;
  }

  useEffect(() => {
    const lossTabName = "rentalLoss";

    const findAttributeData = (attributeCode: string) => {
      let incomeAttributesData: Record<string, unknown> | undefined = undefined;
      if (incomeAttributesState.data) {
        incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
      }
      return incomeAttributesData;
    };


    const initTotalLoss = () => {
      let calculated = false;
      let total = 0;

      const lookupAttributes = lookupState.lookups[1][lossTabName];
      if (lookupAttributes) {
        lookupAttributes.forEach((attribute) => {
          if (attribute.activeFlag === "Y") {
            const code = attribute.incomeAttributeCode;
            const incomeAttributesData = findAttributeData(code);
            if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
              total = total + (incomeAttributesData.value as number);
              calculated = true;
            }
          }
        });
      }
      if (calculated) {
        setTotalLoss(total);
      }
    }

    if (lookupState.success && incomeAttributesState.success) {
      if (totalIncome === undefined) {
        initTotalIncome(lossTabName);
      }

      if (totalLoss === undefined) {
        initTotalLoss();
      }
    }
    // eslint-disable-next-line
  }, [incomeAttributesState.data, incomeAttributesState.success, lookupState.lookups, lookupState.success, lookupType, totalIncome, totalLoss]);

  // const handleTabSelect = (index: number) => {
  //   dispatch(setNextActiveMenuItem({ ...menuState.activeItem, selectedTabIndex: index }));
  // }

  useEffect(() => {
    if (!lookupState.success) {
      dispatch(getLookups(lookupType));
    }
  }, [dispatch, lookupState.success, lookupType]);

  return (
    <div className='partial-width'>
      <div className='content-container'>
        <p className='strong'>{t('revenues.caption', { ns: 'pie' })}</p>
        {
          (!incomeAttributesState.loading && !lookupState.loading) &&
          (menuState.activeItem.hasTabs && tabList ?
            <>
              <div className="accordion">
                <ControlledAccordion providerValue={providerValue}>
                  {tabList.map(tab => {
                    return <AccordionItem
                      header={t(tab.name, { ns: 'navigation' })}
                      key={tab.name}
                      itemKey={tab.name}
                    >
                      <RevenueTab handleTotalIncome={handleTotalIncome}
                        handleTotalLoss={handleTotalLoss}
                        handleCommentChange={handleCommentChange}
                        name={tab.name}
                        lookups={lookupState.lookups[1] ? lookupState.lookups[1][tab.name] : []}
                        incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined}
                        lookupType={lookupType} />
                    </AccordionItem>
                  })}
                </ControlledAccordion>
              </div>
              {/* <Tabs selectedIndex={menuState.activeItem.selectedTabIndex}
              onSelect={handleTabSelect}>
              <TabList className={'tab-container'}>
                {tabList?.map(tab => {
                  return <Tab key={tab.id} selectedClassName='active-tab' className="tab">{t(tab.name)}</Tab>
                })}
              </TabList>
              <div className="tab-footer-container">
                {
                  tabList?.map(tab => {
                    return <TabPanel key={tab.id}>
                      <RevenueTab handleTotalIncome={handleTotalIncome}
                        handleTotalLoss={handleTotalLoss}
                        handleCommentChange={handleCommentChange}
                        name={tab.name}
                        lookups={lookupState.lookups[1] ? lookupState.lookups[1][tab.name] : []}
                        incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined}
                        lookupType={lookupType} />
                    </TabPanel>
                  })
                }
                <div className='tab-container-footer'>
                  {showRevenue ? <div className="total-income">
                    <p>{t('revenues.totalRevenueIncome', { ns: 'pie' })} </p>
                    <p>$ {formatCurrency(totalIncome, i18n.language)}</p>
                  </div> :
                    <>
                      {lookupType === "htl-income-attributes" || lookupType === "rho-income-attributes" ? <></> :
                        <div className="total-loss">
                          <p>{t('revenues.totalRevenueLoss', { ns: 'pie' })}</p>
                          <p>$ {formatCurrency(totalLoss, i18n.language)}</p>
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </Tabs> */}
            </>
            :
            <div>
              <RevenueTab handleTotalIncome={handleTotalIncome}
                handleTotalLoss={handleTotalLoss}
                handleCommentChange={handleCommentChange}
                name={menuState.activeItem.name}
                lookups={lookupState.lookups[1] ? lookupState.lookups[1][menuState.activeItem.name] : []}
                incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined}
                lookupType={lookupType} />
            </div>
          )
        }
      </div>
      <div className='total-container'>
        <Total text={t('revenues.totalRevenueIncome', { ns: 'pie' })} value={totalIncome} id={'totalIncome'} />
        {!noLossTypes.includes(lookupType) &&
          <Total text={t('revenues.totalRevenueLoss', { ns: 'pie' })} value={totalLoss}
            className='secondary' space={false} id={'totalLoss'} />
        }
      </div>
    </div>);
}