import React from 'react';
import "../GuidedExperience/GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../common/CurrencyInput/CurrencyInput';
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';

interface ParkingRateWizardProps {
    totalPropertyNameFromThePreviousStep: string
    type: string;
    propertyName: string;
    parkings: Record<string, unknown>;
    setParkings: (value: Record<string, unknown>) => void;
}

export default function ParkingRateWizard(props: ParkingRateWizardProps): JSX.Element {
    const {totalPropertyNameFromThePreviousStep, type, propertyName, parkings, setParkings} = props;
    const { t } = useTranslation("guided_wizard");
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const currencySymbol = (globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"] as string;

    const handleCurrencyChange = (value: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
        parkings[name] = (value === undefined) ? null : value;
        setParkings({...parkings});
    };

    return (<div className='multires-wizard-parking-rate'>
        <p>{t(`${type}-wizard.${type}-description-1`)}<b><u>{parkings[totalPropertyNameFromThePreviousStep] as number}</u></b>{t(`${type}-wizard.${type}-description-2`)}</p>
        <CurrencyInput name={propertyName} id={type}
            value={parkings[propertyName] as number | null}
            onChange={handleCurrencyChange}
            placeholder={"(" + currencySymbol + ")"} />
    </div>)
}