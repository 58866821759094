import { API_REQUEST, RESET_VALIDATE_REFRESH_TOKEN, VALIDATE_INTERNAL_TOKEN, VALIDATE_REFRESH_TOKEN, VALIDATE_TOKEN } from "../../actionTypes/actionsTypes";
import { AppAction, LocalAction } from "../../types";

export const validateToken = (token: string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: '/login',
        next: VALIDATE_TOKEN,
        method: 'POST',
        data: {
            "token": token
        }
    }
});

export const validateInternalToken = (token: string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: '/verify-internal-token',
        next: VALIDATE_INTERNAL_TOKEN,
        method: 'POST',
        data: {
            "token": token
        }
    }
});

export const validateRefreshToken = (refresh_token: string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/refresh`,
        next: VALIDATE_REFRESH_TOKEN,
        method: 'POST',
        data: {
            "refresh": refresh_token
        },
        authorization: true
    }
});

export const resetValidateRefreshToken = (): LocalAction => ({
    type: RESET_VALIDATE_REFRESH_TOKEN
});