/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 * Update: 18/04/2024 - Mariam Bawa - Cast ELS Income attribute response to work with updated LookupsState
 */
import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from '../../../redux/actions/incomeAttributes/incomeAttributes';
import { getLookups } from '../../../redux/actions/lookups/lookups';
import { setNextActiveMenuItem } from '../../../redux/actions/menu/menu';
import { resetSaving } from '../../../redux/actions/saveData/saveData';
import { RootState } from '../../../redux/reducers/root';
import { IncomeAttributeState } from '../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer';
import { LookupsState } from '../../../redux/reducers/getLookups/lookupsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import ExpenseTab from './ExpenseTab';
import Asterisk from '../../../components/common/Asterisk/Asterisk';
import MenuState from '../../../types/menu/MenuState';
import { initialMenuItem } from '../../../redux/reducers/getMenu/getMenuReducer';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { ControlledAccordion, useAccordionProvider } from '@szhsin/react-accordion';
import AccordionItem, { DefaultAccordionProviderOptions } from '../../../components/common/AccordionItem/AccordionItem';
import Total from '../../../components/common/Total/Total';
import "../PIE.css";

export default function ExpenseContainer(): JSX.Element {
  const { t } = useTranslation(['navigation', 'pie']);
  const dispatch = useDispatch();
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const tabList = menuState.activeItem.subItems;
  const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const [totalExpense, setTotalExpense] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const requiredAttributes: Record<string, Record<string, string[]>> = {
    "bip": { "expenses": ['54', '49'] },
    "rho": { "fixedCharges": ['49', '54'] }
    // "ltc": { "fixedCharges" : ['49', '54']}
  };
  const atLeastOneAttributes: Record<string, Record<string, string[]>> = {
    "rho": { "expenses": ['41', '40', '78', '79', '80', '81', '63', '82', '83', '46', '44', '47', '48', '52', '84', '51'] }
    // "ltc": { "fixedCharges" : ['41', '79', '85', '66', '78', '80', '81', '47', '83', '48', '51', '46', '44', '63', '86', '52', '84', '87']}
  };

  const providerValue = useAccordionProvider(DefaultAccordionProviderOptions)

  const findAttributeData = (attributeCode: string) => {
    let incomeAttributesData: Record<string, unknown> | undefined = undefined;
    if (incomeAttributesState.data) {
      incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
    }
    return incomeAttributesData;
  };

  const validateRequired = (attributeCode: string, errors: Record<string, string>, t: TFunction) => {
    const attribute = findAttributeData(attributeCode);
    if (attribute && attribute.value) {
      errors = { ...errors, [attributeCode]: "" };
    } else {
      errors = { ...errors, [attributeCode]: t('validation.' + attributeCode, { ns: 'pie' }) };
    }
    return errors;
  }

  const validateAtLeastOne = (attributeCodes: string[], errors: Record<string, string>, t: TFunction) => {
    let found = false;
    attributeCodes.forEach((code) => {
      const attribute = findAttributeData(code);
      if (attribute && attribute.value) {
        found = true;
      }
    })
    if (found) {
      errors = { ...errors, "atLeastOne": "" };
    } else {
      errors = { ...errors, "atLeastOne": t('validation.atLeastOne', { ns: 'pie' }) };
    }
    return errors;
  }

  const hasError = (errors: Record<string, string>) => {
    let hasError = false;
    for (const errorKey in errors) {
      if (errors[errorKey].length > 0) {
        hasError = true;
        break;
      }
    }
    return hasError;
  }

  const validate = (showError = true) => {
    if (localStorage.getItem("isInternalUser")) {
      return true;
    }

    let errors = {};
    const profileType = profileOverview.profile.profile_type_code.toLowerCase();
    const requiredCodes = requiredAttributes[profileType] ? requiredAttributes[profileType][menuState.activeItem.name] : undefined;
    const atLeastOneCodes = atLeastOneAttributes[profileType] ? atLeastOneAttributes[profileType][menuState.activeItem.name] : undefined;

    if (requiredCodes) {
      requiredCodes.forEach((code) => {
        errors = validateRequired(code, errors, t);
      });
    }
    if (atLeastOneCodes) {
      errors = validateAtLeastOne(atLeastOneCodes, errors, t);
    }
    if (showError) {
      setErrors(errors);
    }
    if (hasError(errors)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if ((!incomeAttributesState.loading && !incomeAttributesState.success)
      || incomeAttributesState.snapshotId !== Number(localStorage.getItem('currentSnapshotId') as string)
    ) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (saveData.saving) {
      if (validate()) {
        const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
        dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes, menuState.activeItem.name));
      } else {
        dispatch(resetSaving());
        dispatch(setNextActiveMenuItem(initialMenuItem));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, incomeAttributesState.data, menuState.activeItem.name, profileOverview.profile.profile_type_code, saveData.changesPending, saveData.saving, t])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
  }, [dispatch, saveResultState]);

  const handleTotalExpense = (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => {
    let calculated = false;
    let value = 0;
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const na = newAmt ? newAmt : null;
      if (incomeAttributesData) {
        incomeAttributesData["value"] = na;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: null,
          value: na,
          type: "2",
          percent: null,
          year: null,
        });
      }
    }

    if (typeof totalExpense === "number") {
      value = totalExpense;
    }
    if (typeof newAmt === "number") {
      value = value + newAmt;
      calculated = true;
    }
    if (typeof oldAmt === "number") {
      value = value - oldAmt;
      calculated = true;
    }
    if (calculated) {
      setTotalExpense(value);
    }
  }

  const handleCommentChange = (value: string, name: string) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const v = value.trim() === "" ? null : value.trim();
      if (incomeAttributesData) {
        incomeAttributesData["description"] = v;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: v,
          value: null,
          type: "2",
          percent: null,
          year: null,
        });
      }
    }
  }

  useEffect(() => {
    if (!lookupState.success) {
      dispatch(getLookups(lookupType));
    }
  }, [dispatch, lookupState.success, lookupType]);

  useEffect(() => {
    if (lookupState.success && incomeAttributesState.success) {
      let calculated = false;
      let total = 0;

      if (menuState.activeItem.hasTabs) {
        const keys = Object.keys(lookupState.lookups[2]);
        keys.forEach((key) => {
          const lookupAttributes = lookupState.lookups[2][key];
          lookupAttributes.forEach((attribute) => {
            if (attribute.activeFlag === "Y") {
              const code = attribute.incomeAttributeCode;
              const incomeAttributesData = findAttributeData(code);
              if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
                total = total + (incomeAttributesData.value as number);
                calculated = true;
              }
            }
          });
        });
      } else {
        const lookupAttributes = lookupState.lookups[2][menuState.activeItem.name];
        if (lookupAttributes) {
          lookupAttributes.forEach((attribute) => {
            if (attribute.activeFlag === "Y") {
              const code = attribute.incomeAttributeCode;
              const incomeAttributesData = findAttributeData(code);
              if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
                total = total + (incomeAttributesData.value as number);
                calculated = true;
              }
            }
          });
        }
      }
      if (calculated) {
        setTotalExpense(total);
      }
    }
    // eslint-disable-next-line
  }, [incomeAttributesState.success, lookupState.lookups, lookupState.success, lookupType, totalExpense, menuState.activeItem]);

  return (
    <div className='partial-width'>
      <div className='content-container'>
        <p className='strong'>{t('expenses.caption1', { ns: 'pie' })} {t(menuState.activeItem.name, { ns: 'navigation' }).toLowerCase()} {t('expenses.caption2', { ns: 'pie' })}</p>
        {
          (menuState.activeItem.hasTabs && tabList) ?
            <>
              <div className="accordion">
                <ControlledAccordion providerValue={providerValue}>
                  {tabList.map(tab => {
                    return <AccordionItem header={t(tab.name, { ns: 'navigation' })}
                      key={tab.name}
                      itemKey={tab.name}
                    >
                      <ExpenseTab handleTotalExpense={handleTotalExpense}
                        handleCommentChange={handleCommentChange}
                        lookups={lookupState.lookups[2] ? lookupState.lookups[2][tab.name] : []}
                        incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined}
                        errors={errors}
                      />
                    </AccordionItem>
                  })}
                </ControlledAccordion>
              </div>
            </>
            :
            <>
              <ExpenseTab handleTotalExpense={handleTotalExpense}
                lookups={lookupState.lookups[2] ? lookupState.lookups[2][menuState.activeItem.name] : []}
                handleCommentChange={handleCommentChange}
                incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined}
                errors={errors} />
            </>
        }
      </div>
      <div className='total-container'>
        <Total text={t('expenses.totalExpenses', { ns: 'pie' })} value={totalExpense} />
      </div>
      {((!(menuState.activeItem.name === "fixedCharges") && (menuState.activeItem.name === "expenses" && menuState.activeItem.selectedTabIndex === 0))) && <p><Asterisk /> {t('revenues.atLeaseOneRequired', { ns: 'pie' })}</p>}
    </div>);
}