/**
 * Author: Mariam Bawa (mariam.bawa@mpac.ca)
 * Date Created: April 18, 2024
 * Context: Utility function created to generate table content for income attributes. Works with objects of type incomeAttributeLookupObject
 */

import React from "react";
import { useTranslation } from "react-i18next";
import CurrencyInput from "../../components/common/CurrencyInput/CurrencyInput";
import Asterisk from "../../components/common/Asterisk/Asterisk";

interface AttributeTableFragmentProps {
  name: string,
  data: number | null,
  isRequiredField: boolean,
  translationFile: string,
  valueChangeHandler?: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
  errors?: Record<string, string>
}
export default function ProfileAttributeTableFragmentGenerator(props: AttributeTableFragmentProps): React.ReactNode {
  const { t } = useTranslation(props.translationFile);
  const { name, data, isRequiredField } = props;

  return (
    <tr>
      <td className='label' headers="attribute-label">
        {t(`headers.${name}`)}
        {isRequiredField && <>&nbsp;<Asterisk /></>}
      </td>
      <td className="value" headers="attribute-value">
        <CurrencyInput name={name}
          onChange={props.valueChangeHandler}
          value={data && data as number}
          errormessage={props.errors ? props.errors[name] : undefined}
        />
      </td>
    </tr>
  );
}