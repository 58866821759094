import { combineReducers } from "redux";
import getValidTokenReducer from "./validateTokenReducer";
import getLogoutReducer from "./logoutReducer";
import getValidInternalTokenReducer from "./validateInternalTokenReducer";
import getRefreshTokenReducer from "./validateRefreshTokenReducer";

const authReducer = combineReducers({
    validateToken: getValidTokenReducer,
    validateInternalToken: getValidInternalTokenReducer,
    logout: getLogoutReducer,
    validateRefreshToken: getRefreshTokenReducer
});

export default authReducer;