import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/common/Button/Button';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import LandingBanner from '../../../components/LandingBanner/LandingBanner';
import { getElsLookups } from '../../../redux/actions/lookups/lookups';
import { GetElsLookupsState } from '../../../redux/reducers/getLookups/getElsLookupsReducer';
import { RootState } from '../../../redux/reducers/root';
import "./Continue.css";
import { ProfileDetailsState } from '../../../redux/reducers/getProfile/getProfileDetailsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { getProfileDetails, getProfileOverview, resetGetProfileDetails } from '../../../redux/actions/profile/profile';
import { toast } from 'react-toastify';
import Header from '../../../layouts/Header/Header';
import Footer from '../../../layouts/Footer/Footer';
import { setReadOnly } from '../../../redux/actions/readOnly/readOnly';

export default function Continue(): JSX.Element {

    const { t } = useTranslation('transition');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isProfileDetailsReqSent, setIsProfileDetailsReqSent] = useState(false);

    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const [continuing, setContinuing] = useState(false);
    const getProfileOverviewDispatched = useRef(false)
    const rollNumber = localStorage.getItem('rollNumber') as string
    const profileId = localStorage.getItem('profileId') as string

    useEffect(() => {
        dispatch(resetGetProfileDetails())
    }, [dispatch]);

    useEffect(() => {
        if (!getProfileOverviewDispatched.current &&
            !(profileOverview?.profile?.profile_id === Number(profileId))
        ) {
            dispatch(getProfileOverview(profileId))
            getProfileOverviewDispatched.current = true
        }
    }, [dispatch, profileId, profileOverview])

    useEffect(() => {
        if (profileOverview.found && String(profileOverview.profile.profile_id) === localStorage.getItem("profileId")) {
            localStorage.removeItem('incomeReturnType'); // remove incomeReturnType for WIP as it's irrelevant
            localStorage.setItem('rollNumber', rollNumber)
            localStorage.setItem('profileId', String(profileOverview.profile.profile_id));
            localStorage.setItem('activeCampaignYear', String(profileOverview.profile.current_campaign.year));
            localStorage.setItem('campaignId', String(profileOverview.profile.current_campaign.campaignId));
            localStorage.setItem('currentSnapshotId', String(profileOverview.profile.current_campaign.currentSnapshotId));
            if (getProfileOverviewDispatched.current) {
                getProfileOverviewDispatched.current = false
            }
        }
    }, [dispatch, profileOverview, rollNumber]);

    const continueReturn = (e: React.MouseEvent) => {
        e.preventDefault();
        if (localStorage.getItem('currentSnapshotId')) {
            setIsProfileDetailsReqSent(true);
            dispatch(getProfileDetails(Number(localStorage.getItem('currentSnapshotId') as string)));
        }
        setContinuing(true);
        dispatch(setReadOnly(false))

        if (!elsLookupsState.success) {
            dispatch(getElsLookups());
        }
    }

    useEffect(() => {
        if (isProfileDetailsReqSent && !profileDetails.loading && continuing) {
            // Verify status code before proceeding to portal
            if (elsLookupsState.success && profileDetails.success) {
                setContinuing(false);
                navigate('/portal')
            } else if (!profileDetails.success) {
                toast.error("Failed to retrieve profile details");
            }
        }
    }, [isProfileDetailsReqSent, profileDetails, elsLookupsState, continuing, navigate, profileOverview, rollNumber]);

    return (<>
        <Header showAutoSave={false} isInternalUser={false} />
        <LandingBanner />
        <LoadingSpinner loading={profileOverview.loading || profileDetails.loading || continuing}>
            <div className='continue-body-container'>
                <div className='continue-body'>
                    <h1>{t("continue.welcome")}!</h1>
                    <p>{`${t("continue.saved")}`}</p>
                        <p className="strong">{rollNumber}</p>
                        <p>
                        <div className="button-group">
                            <Button onClick={e => continueReturn(e)} type="submit" text={t("continue.continue")} />
                            <Button onClick={() => navigate('/landing')} type="secondary" text={t("return")} />
                        </div>
                        </p>
                </div>
            </div>
        </LoadingSpinner>
        <div className="start-footer">
            <Footer />
        </div>
    </>);
}
