import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setNextActiveMenuItem } from "../../redux/actions/menu/menu";
import { setSaving } from "../../redux/actions/saveData/saveData";
import { RootState } from "../../redux/reducers/root";
import AnchorAsButton from "../../components/common/AnchorAsButton/AnchorAsButton";
import Button from "../../components/common/Button/Button";
import FontSizeSwapper from "../../components/common/FontSizeSwapper/FontSizeSwapper";
import MenuItem from "../../types/menu/MenuItem";
import MenuState from "../../types/menu/MenuState";
import './Footer.css';
import { useReadOnly } from "../../utils/ReadOnlyContext";
import Modal from "../../components/common/Modal/Modal";
import Toc from "../../components/common/Toc/Toc";

function Footer(): JSX.Element {
    const { i18n } = useTranslation();
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const [orderedMenu, setOrderedMenu] = useState([] as Array<MenuItem>);
    const { isReadOnly } = useReadOnly();
    const [showTocModal, setShowTocModal] = useState(false)

    const indexOf = (activeMenuItem: MenuItem) => {
        for (let i = 0; i < orderedMenu.length; i++) {
            if (activeMenuItem.id === orderedMenu[i].id) {
                const selectedTabIndex = activeMenuItem.selectedTabIndex;
                if (selectedTabIndex !== null && selectedTabIndex !== undefined) {
                    if (selectedTabIndex !== orderedMenu[i].selectedTabIndex) {
                        continue;
                    }
                    return i;
                }
                return i;
            }
        }
        return -1;
    }

    const handleNext = () => {
        const index = indexOf(menuState.activeItem);
        if (index === orderedMenu.length - 1) {
            return;
        }

        let newIndex = index;
        do {
            newIndex++;
            // if (orderedMenu[newIndex].componentName) {
            dispatch(setNextActiveMenuItem(orderedMenu[newIndex]));
            break;
            // }
        } while (newIndex < orderedMenu.length);
    };

    const handleBack = () => {
        const index = indexOf(menuState.activeItem);
        if (index < 1) {
            return;
        }

        let newIndex = index;
        do {
            newIndex--;
            // if (orderedMenu[newIndex].componentName) {
            dispatch(setNextActiveMenuItem(orderedMenu[newIndex]));
            break;
            // }
        } while (newIndex > 0);
    };

    const handleSave = () => {
        dispatch(setSaving());
    };

    useEffect(() => {
        if (menuState.menu && menuState.menu.length > 0) {
            const om = [] as Array<MenuItem>;
            const sort = (root: Array<MenuItem> | null | undefined) => {
                if (root && root.length > 0) {
                    for (let i = 0; i < root.length; i++) {
                        if (root[i].componentName) {
                            // if (root[i].hasTabs && root[i].subItems) {
                            //     // om.push({...root[i], selectedTabIndex: 0});
                            //     for (let j = 0; j < (root[i].subItems as MenuItem[]).length; j++) {
                            //         om.push({ ...root[i], selectedTabIndex: j });
                            //     }
                            //     continue;
                            // }
                            om.push(root[i]);
                        }
                        sort(root[i].subItems);
                    }
                }
                return;
            };
            sort(menuState.menu);
            setOrderedMenu(om);
        }
    }, [menuState.menu]);

    return (
        <footer className="footer-root">
            {
                window.location.href.includes("#/portal") &&
                <div className="footer-button-holder">
                    <Button onClick={() => handleBack()}>{t("back").toLocaleUpperCase(i18n.language)}</Button>
                    <Button onClick={() => handleNext()}>{t("next").toLocaleUpperCase(i18n.language)}</Button>
                    {!isReadOnly && <Button onClick={() => handleSave()}>{t("save").toLocaleUpperCase(i18n.language)}</Button>}
                </div >
            }
            <div className="footer-links-container">
                <div className="footer-text">
                    <FontSizeSwapper />
                </div>
                <div className="footer-text">
                    <AnchorAsButton onClick={() => window.open(t("privacy-link"), "_blank", "noreferrer")}>{t("privacy-policy")}</AnchorAsButton>
                </div>
                <div>
                    <AnchorAsButton onClick={() => setShowTocModal(true)}>{t("terms-and-conditions")}</AnchorAsButton>
                </div>
                <div className="footer-text all-rights-reserved">
                    <AnchorAsButton>{t("all-rights-reserved")}</AnchorAsButton>
                </div>
                {showTocModal &&
                    <Modal
                        onCloseModal={() => setShowTocModal(false)}
                        title={t("title", { ns: "toc" })}>
                        <Toc />
                    </Modal>
                }
            </div>
        </footer >
    );
}

export default Footer;