import { RESET_VALIDATE_REFRESH_TOKEN, VALIDATE_REFRESH_TOKEN } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState } from "../../types";

export interface ValidateRefreshTokenState extends BaseAsyncReducerState {
  valid: boolean,
  exp?: number,
  token?: string,
  refresh?: string,
  message?: string
}

const initialState: ValidateRefreshTokenState = {
  valid: false,
  loading: false,
  error: false
}

function getRefreshTokenReducer(state = initialState, action: AppAction) {
  if (action.type === RESET_VALIDATE_REFRESH_TOKEN) {
    return initialState;
  } else {
    return defaultAsyncReducer(state, action, VALIDATE_REFRESH_TOKEN);
  }
}

export default getRefreshTokenReducer;